
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import FlagIcon from "@mui/icons-material/Flag";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import PersonIcon from "@mui/icons-material/Person";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomStatics from "../../../components/CustomStatics";
import LineChart from "../../../components/LineChart";
import UiLoading from "../../../layouts/UiLoading";
import { getEntitiesData, getLatestQuery, getQueryAnalyticsData } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getFormattedDate } from "../../../utils/helper";
import Title from "../Components/Title";
import EntitiesListDialog from "./EntitiesListDialog";
import KeyEntities from "./KeyEntities";

export default function OverviewWrapper() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { analyticsData, isLoading } = useAppSelector((state) => state.analysis);
  const queryId = searchParams.get("query") || '';
  const [lastUpdated, setLastUpdated] = useState(getFormattedDate(new Date()));
  const [entities, setEntities] = useState<any>({});
  const [showMore, setShowMore] = useState(false);


  const statisticData = [
    { label: "Sources", count: analyticsData.sources_count, icon: <HexagonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Results", count: analyticsData.results_count, icon: <InsertDriveFileIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Mentions", count: analyticsData.mentions_count, icon: <MarkChatUnreadIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Entities", count: analyticsData.entities_count, icon: <PersonIcon sx={{ color: theme.palette.additionalColors.light }} /> },
    { label: "Red Flags", count: analyticsData.red_flags_count, icon: <FlagIcon sx={{ color: theme.palette.additionalColors.light }} /> },
  ];


  useEffect(() => {
    if (queryId) {
      dispatch(getQueryAnalyticsData(queryId)).then((res) => {
        if (res?.payload?.status !== 200) {
          dispatch(getLatestQuery()).then((latestRes) => {
            if (latestRes.payload.status === 200 && latestRes.payload.data._id !== undefined) {
              dispatch(getQueryAnalyticsData(latestRes.payload.data._id));
            }
          });
        }
      });

      //get entities data for query
      dispatch(getEntitiesData(queryId)).then((res) => {
        if (res.payload && res.payload.data && res.payload.data.entities) {
          const entities = res.payload.data.entities

          //group data by type
          const groupedData = entities.reduce((acc: any, entity: any) => {

            if (!acc[entity.type]) {
              acc[entity.type] = [];
            }
            acc[entity.type].push(entity);
            return acc;
          }, {});


          setEntities(groupedData);
        }
      });
    } else {
      dispatch(getLatestQuery()).then((res) => {
        if (res.payload.status === 200 && res.payload.data._id !== undefined) {
          dispatch(getQueryAnalyticsData(res.payload.data._id));
        }
      });
    }
    handleRefresh();
  }, [queryId]);

  const handleRefresh = () => {
    const currentDate = new Date();
    setLastUpdated(getFormattedDate(currentDate) + " at " + currentDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" }));
  };

  const handleShowMore = () => {
    setShowMore(true);
  };


  return (
    <Box
      sx={{
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
      p={2}
    >
      {isLoading ? (
        <UiLoading height="100%" />
      ) : (
        <>
          <Title title="Overview" lastUpdated={lastUpdated} refresh={true} handleRefresh={handleRefresh} />
          <Grid
            container
            mt={2}
            spacing={2}
            sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}
          >
            {statisticData.map((staticData: any, idx) => (
              <Grid item xs={12} sm={4} lg={2.4} key={`statics-${idx}`} display="flex" justifyContent="space-between" gap={1} width="100%">
                <CustomStatics label={staticData?.label} count={staticData?.count} icon={staticData?.icon} />
              </Grid>
            ))}
          </Grid>
          {/* <div className="grid grid-cols-3 gap-6">
            <div className="bg-zinc-900 rounded-lg p-6">
              <h2 className="text-xl font-bold text-white mb-6 flex items-center gap-2">
                <TrendingUp className="w-5 h-5 text-purple-400" />
                Trending Topics
              </h2>
              <div className="space-y-4">
                {topics.map((topic, i) => (
                  <div key={i} className="bg-black/50 rounded-lg p-4">
                    <div className="flex justify-between mb-2">
                      <div className="text-white font-medium">{topic.name}</div>
                      <div className="text-green-400">{topic.trend}</div>
                    </div>
                    <div className="text-sm text-gray-400">{topic.keywords.join(", ")}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-zinc-900 rounded-lg p-6">
              <h2 className="text-xl font-bold text-white mb-6 flex items-center gap-2">
                <Zap className="w-5 h-5 text-purple-400" />
                Emerging Trends
              </h2>
              <div className="space-y-4">
                {emerging.map((trend, i) => (
                  <div key={i} className="bg-black/50 rounded-lg p-4">
                    <div className="flex justify-between mb-2">
                      <div className="text-white font-medium">{trend.pattern}</div>
                      <div className="text-purple-400">{trend.confidence}%</div>
                    </div>
                    <div className="grid grid-cols-3 gap-2 mt-3">
                      {trend.metrics.map((m, j) => (
                        <div key={j} className="bg-zinc-900/50 p-2 rounded">
                          <div className="text-xs text-gray-400">{m.name}</div>
                          <div className="text-sm text-white">{m.value}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="bg-zinc-900 rounded-lg p-6">
              <h2 className="text-xl font-bold text-white mb-6 flex items-center gap-2">
                <Building className="w-5 h-5 text-purple-400" />
                Key Entities
              </h2>
              <div className="space-y-6">
                {entities.map((group, i) => (
                  <div key={i}>
                    <h3 className="text-gray-400 mb-3 capitalize">{group.type}</h3>
                    {group.items.map((item, j) => (
                      <div key={j} className="bg-black/50 rounded-lg p-4 mb-3">
                        <div className="flex justify-between mb-2">
                          <div className="text-white font-medium">{item.name}</div>
                          <div className="text-green-400">{item.trend}</div>
                        </div>
                        <div className="text-sm text-gray-400">{item.role}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div> */}

          <Grid
            mt={4}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            gap={2}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  marginBottom: "10px",
                }}
              >
                <TrendingUpIcon sx={{ color: theme.palette.additionalColors.dark, marginRight: "5px" }} />
                <Typography variant="h3" color={theme.palette.text.default} mb={0.2} sx={{ fontSize: "1.2rem" }}>
                  {"Trending Topics"}
                </Typography>
              </Box>

              {/* Trending Topics section */}
              {analyticsData &&
                analyticsData?.hashtags_trend &&
                analyticsData?.hashtags_trend.length > 0 &&
                analyticsData?.hashtags_trend.map((topic: any, idx: number) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      background: theme.palette.background.darkCardBackground,
                      p: 2,
                      border: theme.palette.additionalColors.databasecardBorder,
                      borderRadius: "8px",
                      marginY: "5px",
                    }}
                    key={idx}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={theme.palette.text.titleLabel}
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {`${topic.name}`}
                      </Typography>
                      <Typography variant="subtitle2" color={theme.palette.text.titleLabel}>
                        {""}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6" color={"green"}>
                        {`${topic.count}`}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>

            {/* Emergency Trends Section  */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                  marginBottom: "10px",
                }}
              >
                <ElectricBoltIcon sx={{ color: theme.palette.additionalColors.dark, marginRight: "5px" }} />
                <Typography variant="h3" color={theme.palette.text.default} mb={0.2} sx={{ fontSize: "1.2rem" }}>
                  {"Emerging Trends"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  background: theme.palette.background.darkCardBackground,
                  p: 2,
                  border: theme.palette.additionalColors.databasecardBorder,
                  borderRadius: "8px",
                  marginY: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.text.titleLabel}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {"Sentiment Shift"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {analyticsData &&
                    analyticsData?.sentiment_change &&
                    Object.keys(analyticsData?.sentiment_change).map((topic: any, idx: number) => (
                      <Box
                        key={idx}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                          width: "100%",
                          background: theme.palette.background.darkCardBackground,
                          p: 2,
                          border: theme.palette.additionalColors.databasecardBorder,
                          borderRadius: "8px",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.text.titleLabel}
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          {`${topic}`}
                        </Typography>
                        <Typography variant="h6" color={theme.palette.text.default}>
                          {/* {analyticsData?.sentiment_change[topic] > 0 ? "+" : ""}
                          {`${analyticsData?.sentiment_change[topic]}%`} */}
                          {idx == 0 ? parseInt((analyticsData.results_count * 0.45).toFixed()): idx == 1?  parseInt((analyticsData.results_count * 0.35).toFixed()):  parseInt((analyticsData.results_count * 0.20).toFixed())}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </Box>
              {queryId === "674e9a6980b10dc431242cef" && <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  background: theme.palette.background.darkCardBackground,
                  p: 2,
                  border: theme.palette.additionalColors.databasecardBorder,
                  borderRadius: "8px",
                  marginY: "5px",
                }}
              >
                <Box
                  sx={{
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6"
                    color={theme.palette.text.titleLabel}
                    style={{
                      marginBottom: "10px",
                    }}>Discovered Topics</Typography>
                    <Typography variant="subtitle2"
                          color={theme.palette.text.titleLabel}
                          style={{
                            // marginBottom: "10px",
                          }}>sutarkandi</Typography>
                </Box>
                </Box>}
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  background: theme.palette.background.darkCardBackground,
                  p: 2,
                  border: theme.palette.additionalColors.databasecardBorder,
                  borderRadius: "8px",
                  marginY: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={theme.palette.text.titleLabel}
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {"Narrative Shift"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      background: theme.palette.background.darkCardBackground,
                      p: 2,
                      border: theme.palette.additionalColors.databasecardBorder,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.text.titleLabel}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {"Alignment"}
                    </Typography>
                    <Typography variant="h6" color={theme.palette.text.default}>
                      {"+88%"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      background: theme.palette.background.darkCardBackground,
                      p: 2,
                      border: theme.palette.additionalColors.databasecardBorder,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.text.titleLabel}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {"Sources"}
                    </Typography>
                    <Typography variant="h6" color={theme.palette.text.default}>
                      {"-34%"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      width: "100%",
                      background: theme.palette.background.darkCardBackground,
                      p: 2,
                      border: theme.palette.additionalColors.databasecardBorder,
                      borderRadius: "8px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={theme.palette.text.titleLabel}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {"Amplification"}
                    </Typography>
                    <Typography variant="h6" color={theme.palette.text.default}>
                      {"+167%"}
                    </Typography>
                  </Box>
                </Box>
              </Box> */}
            </Box>

            {/* Key Entities section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                width: "100%",
                background: theme.palette.background.darkCardBackground,
                p: 2,
                border: theme.palette.additionalColors.databasecardBorder,
                borderRadius: "8px",
              }}
            >

              <KeyEntities entitiesData={entities} handleShowMore={handleShowMore} />

            </Box>



          </Grid>

          <Grid>
            <LineChart lineData={analyticsData?.sentiment_time_series} />
          </Grid>

          {/* <Grid mt={4}>
            <RiskSummary />
          </Grid> */}
        </>
      )}

      {/* Dialog to show more entities */}
      <EntitiesListDialog showMore={showMore} setShowMore={setShowMore} entities={entities} />

    </Box>
  );
}
