import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ForceGraph2D from "react-force-graph-2d";
import ZoomController from "../../../../../components/ZoomController";
import { useAppSelector } from "../../../../../store/hooks";
import { BASE_URL } from "../../../../../utils/constants";
import ConnectionsPopover from "./ConnectionsPopover/ConnectionsPopover";
import LinkPopoverContent from "./ConnectionsPopover/LinkPopoverContent";
import NodePopoverContent from "./ConnectionsPopover/NodePopoverContent";
import EntityDetailsDialog from "./EntityDetailsDialog";

interface NewNetworkGraphProps {
    setSelectedNode: (nodeId: string | null) => void;
    visiblePosts: string[];
    onNodeClick: (nodeId: string, posts: string[]) => void;
}

const NewNetworkGraph: React.FC<NewNetworkGraphProps> = ({ setSelectedNode, visiblePosts, onNodeClick }) => {
    const theme = useTheme();
    const graphRef = useRef<any>(null); // Create a ref for the ForceGraph2D component
    const { hashtags } = useAppSelector((state) => state.analysisConnections);
    const { query } = useAppSelector((state) => state.analysisQuery);
    const queryId = query._id;

    const [graphData, setGraphData] = useState<any>({
        nodes: [],
        links: [],
    });
    const [posts, setPosts] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [zoom, setZoom] = useState<number>(3);
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [currentLink, setCurrentLink] = useState<Record<string, any> | null>(null);
    const [currentNode, setCurrentNode] = useState<any | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [coords, setCoords] = useState<number[]>([0, 0]);

    useEffect(() => {
        const nodes = hashtags.map((hashtag) => ({
            id: hashtag.hashtag_id,
            name: hashtag.name,
            count: hashtag.count,
        }));

        const links = hashtags.flatMap((hashtag) =>
            hashtag.connected_hashtags?.map((connectedHashtag: string) => ({
                source: hashtag.hashtag_id,
                target: connectedHashtag,
            })) || []
        );

        setGraphData({ nodes, links });
    }, [hashtags]);

    useEffect(() => {
        if (graphRef.current) {
            const graph = graphRef.current;
            const d3Force = graph.d3Force("charge");
            d3Force.strength(-150); // Adjust the repulsion force to avoid nodes clustering
            graph.d3Force("link").distance(130); // Adjust link distance for readability
        }
    }, [graphData]);

    const handleZoomOut = () => {
        if (zoom < 20) setZoom((prev) => prev + 1);
    };
    const handleZoomIn = () => {
        if (zoom > 1) setZoom((prev) => prev - 1);
    };

    const handleLinkClick = (link: Record<string, any>, e: MouseEvent) => {
        setCurrentNode(null);
        setCurrentLink(link);
        const localX = e.clientX;
        const localY = e.clientY;
        setCoords([localX, localY]);
        setOpenPopover(true);
    };

    const drawLink = (link: any, ctx: CanvasRenderingContext2D) => {
        const source = link.source;
        const target = link.target;

        if (!source || !target) return;

        const dx = target.x - source.x;
        const dy = target.y - source.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const radiusSource = source.count > 10 ? 15 : 10; // Node radius based on count
        const radiusTarget = target.count > 10 ? 15 : 10;

        // Normalize direction vector
        const nx = dx / distance;
        const ny = dy / distance;

        // Start and end positions adjusted to the border
        const startX = source.x + nx * radiusSource;
        const startY = source.y + ny * radiusSource;
        const endX = target.x - nx * radiusTarget;
        const endY = target.y - ny * radiusTarget;

        // Draw the link
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.strokeStyle = "#888";
        ctx.lineWidth = 2;
        ctx.stroke();
    };

    const fetchedNodes = new Set(); // Track nodes that have already fetched posts

    const handleNodeClickDetails = async (node: any, event: MouseEvent) => {
        const nodeId = node.id;
        const nodeName = node.name;

        // Toggle logic: If the current node is clicked again
        if (currentNode && currentNode.id === nodeId) {
            // Deselect the node and remove associated post nodes and links
            setSelectedNode(null);
            setPosts([]);
            setGraphData((prev: any) => {
                const filteredNodes = prev.nodes.filter((n: any) => !n.isPost || n.source !== nodeId);
                const filteredLinks = prev.links.filter((link: any) => link.source !== nodeId);

                return {
                    nodes: filteredNodes,
                    links: filteredLinks,
                };
            });
            setCurrentNode(null); // Clear the current node selection
            return;
        }

        // If no queryId, return early
        if (!queryId) {
            console.error("queryId is undefined");
            return;
        }

        setSelectedNode(nodeName);
        setLoading(true);

        try {
            const response = await fetch(
                `${BASE_URL}/lcproto/query/posts_by_hashtag/?hashtag_id=${encodeURIComponent(nodeId)}&query_id=${encodeURIComponent(queryId)}`
            );

            if (response.ok) {
                const data = await response.json();
                const posts = data?.posts || [];
                setPosts(posts);

                // Transform posts into nodes and links
                const transformedPosts = posts.map((post: any) => ({
                    id: `post-${post.post._id}`,
                    name: post.post.authors?.[0]?.name || post.post.source,
                    count: post.post.source === "youtube"
                        ? post.post.likeCount || "0" // If the source is YouTube, use likeCount
                        : post.post.source === "facebook"
                            ? "0" // If the source is Facebook, use followers
                            : post.post.likes || "0", // Otherwise, use likes
                    url: post.post.url || "",
                    isPost: true,
                    source: nodeId, // Associate with the parent node
                }));

                const postLinks = transformedPosts.map((post: any) => ({
                    source: nodeId,
                    target: post.id,
                }));

                setGraphData((prev: any) => {
                    const existingPostNodes = prev.nodes.filter((n: any) => n.isPost && n.source === nodeId);

                    if (existingPostNodes.length === 0) {
                        // Add post nodes and links if they don't already exist
                        return {
                            nodes: [...prev.nodes, ...transformedPosts],
                            links: [...prev.links, ...postLinks],
                        };
                    } else {
                        // If post nodes and links already exist, remove them
                        const filteredNodes = prev.nodes.filter(
                            (n: any) => !transformedPosts.some((post: any) => post.id === n.id)
                        );
                        const filteredLinks: any = []; // Clear the links array

                        return {
                            nodes: filteredNodes,
                            links: filteredLinks,
                        };
                    }
                });

                // Add the nodeId to the fetchedNodes set
                fetchedNodes.add(nodeId);
            } else {
                console.error("Failed to fetch posts for node:", nodeId);
                setPosts([]);
            }
        } catch (error) {
            console.error("Error fetching posts:", error);
            setPosts([]);
        } finally {
            setLoading(false);
        }

        // Handle post node clicks
        const handlePostNodeClick = (post: any) => {
            if (post?.url) {
                window.open(post.url, "_blank"); // Open the post URL in a new tab
            } else {
                console.error("No URL found for this post");
            }
        };

        // Add logic for clicking on a post node
        if (node.isPost) {
            handlePostNodeClick(node);
        }

        // Trigger the external click handler with node ID and associated post IDs
        onNodeClick(nodeId, posts.map((post: any) => post.post._id));
    };

    const getColorBasedOnCount = (count: number) => {
        const maxCount = Math.max(...graphData.nodes.map((node: any) => Number(node.count)));
        const normalizedCount = count / maxCount;
        const brightness = Math.min(1, Math.max(0.3, 1 - normalizedCount * 0.7));
        return brightness;
    };

    const nodePaint = (node: any, ctx: CanvasRenderingContext2D) => {
        const radius = node.count > 20 ? 25 : 20;
        const baseColor = theme.palette.primary.main;
        const brightness = getColorBasedOnCount(node.count);
        const color = adjustColorBrightness(baseColor, brightness);

        // Calculate the center position of the circle
        const centerX = node.x;
        const centerY = node.y;

        // Draw the circle
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        ctx.fillStyle = color + "70";
        ctx.fill();
        ctx.lineWidth = 0.5;
        ctx.strokeStyle = color;
        ctx.stroke();

        // Position the name outside the circle
        ctx.font = "12px sans-serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#fff";

        // Display the name outside the circle
        ctx.fillText(node.name, node.x, node.y - radius - 5);

        // Position the count inside the circle
        ctx.font = "10px sans-serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";

        // Display the count inside the circle
        ctx.fillText(`${node.count}`, centerX, centerY);
    };

    const adjustColorBrightness = (hexColor: string, brightness: number): string => {
        let r = parseInt(hexColor.slice(1, 3), 16);
        let g = parseInt(hexColor.slice(3, 5), 16);
        let b = parseInt(hexColor.slice(5, 7), 16);

        r = Math.round(r * brightness);
        g = Math.round(g * brightness);
        b = Math.round(b * brightness);

        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        return `#${(1 << 24 | (r << 16) | (g << 8) | b).toString(16).slice(1).padStart(6, '0')}`;
    };

    return (
        <Box sx={{ position: "relative", width: "100%", height: 550 }}>
            <ForceGraph2D
                ref={graphRef}
                height={522}
                graphData={graphData}
                nodeCanvasObject={(node, ctx) => nodePaint(node, ctx)}
                linkCanvasObject={drawLink} // Custom link drawing logic
                linkWidth={2}
                linkColor={(link) => theme.palette.secondary.main}
                backgroundColor={theme.palette.background.default}
                cooldownTicks={100}
                enableZoomInteraction={true}
                onLinkClick={handleLinkClick}
                onNodeClick={handleNodeClickDetails}
            />
            <ZoomController sx={{ position: "absolute", right: 20, bottom: 20 }} zoomOut={handleZoomOut} zoomIn={handleZoomIn} />
            {(currentLink || currentNode?.id) && (
                <ConnectionsPopover open={openPopover} coords={coords} handleClose={() => setOpenPopover(false)}>
                    {currentLink && currentLink.relation && <LinkPopoverContent linkData={currentLink} />}
                    {currentNode && <NodePopoverContent nodeData={currentNode} />}
                </ConnectionsPopover>
            )}
            <EntityDetailsDialog open={openDialog} handleToggle={() => setOpenDialog(!openDialog)} nodeData={currentNode} />
        </Box>
    );
};

export default NewNetworkGraph;
