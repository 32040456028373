import { createSlice } from "@reduxjs/toolkit";
import {
  getConnections,
  getEntityConnections,
  getEntityCrawlData,
  getNewConnections,
  getPostsByHashtagQuery,
  getTieredConnections,
} from "./analysisThunk";

// Define the initial state type
export interface ConnectionsInitialStateType {
  isLoading: boolean;
  connections: Record<string, any>[];
  newConnections: Record<string, any>[];
  entityConnections: Record<string, any>[];
  entityCrawlData: Record<string, any>[];
  tieredConnections: Array<Record<string, any>[]>;
  hashtags: Record<string, any>[]; // Add hashtags here
  postsByHashtagQuery: Record<string, any>[]; // Add posts by hashtag
}

// Define the initial state
const connectionsInitialState: ConnectionsInitialStateType = {
  isLoading: false,
  connections: [],
  newConnections: [],
  tieredConnections: [],
  entityConnections: [],
  entityCrawlData: [],
  hashtags: [], // Initialize hashtags
  postsByHashtagQuery: []
};

export const connectionsSlice = createSlice({
  name: "connections",
  initialState: connectionsInitialState,
  reducers: {
    setHashtags(state, action) {
      state.hashtags = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get connections
    builder
      .addCase(getConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.connections = action.payload.data?.connections;
      })
      .addCase(getConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get new connections
    builder
      .addCase(getNewConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getNewConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.newConnections = action.payload.data?.newConnections;
        state.hashtags = action.payload.data?.hashtags || []; // Populate hashtags
      })
      .addCase(getNewConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get posts by hashtag
    builder
      .addCase(getPostsByHashtagQuery.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPostsByHashtagQuery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.postsByHashtagQuery = action.payload.data?.posts || [];
      })
      .addCase(getPostsByHashtagQuery.rejected, (state) => {
        state.isLoading = false;
      });

    // get connections
    builder
      .addCase(getEntityConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntityConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entityConnections = action.payload.data?.connections;
      })
      .addCase(getEntityConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get tiered connections
    builder
      .addCase(getTieredConnections.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getTieredConnections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tieredConnections = JSON.parse(action.payload.data?.tiers);
      })
      .addCase(getTieredConnections.rejected, (state, action) => {
        state.isLoading = false;
      });

    // get entity crawl data
    builder
      .addCase(getEntityCrawlData.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEntityCrawlData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.entityCrawlData = action.payload.data?.crawl_data;
      })
      .addCase(getEntityCrawlData.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const connectionsActions = connectionsSlice.actions;
export default connectionsSlice;
