import { Box, useTheme } from "@mui/material";
import SubHeader from "./Components/SubHeader";

const ChatConatiner = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                height: "82vh",
            }}
        >
            <SubHeader />
        </Box>
    );
}

export default ChatConatiner