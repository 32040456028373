import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomTab from "../../../components/CustomTab";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getLatestQuery, getQueryById } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setActiveAnalysisTab } from "../../../store/ui/uiSlice";
import { textTruncate } from "../../../utils/helper";
import Connectionswrapper from "../Wrappers/Connections";
import Entitieswrapper from "../Wrappers/Entities";
import Feedwrapper from "../Wrappers/Feed";
import Mapwrapper from "../Wrappers/Map";
import Overviewrapper from "../Wrappers/Overview";

export default function SubHeader() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();

  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const { activeAnalysisTab } = useAppSelector((state: Record<string, any>) => state.ui);

  const [searchParams, setSearchParams] = useSearchParams();
  // console.log("Search Params at subHeader ", searchParams)
  const [queryData, setQueryData] = useState<Record<string, any>>(query);

  const queryParam = searchParams.get("subtab");
  const queryId = searchParams.get("query");
  const [activeTab, setActiveTab] = useState(queryParam || "overview");

  // console.log("searchparams: ", searchParams);
  // console.log("queryData :", queryData);

  const tabs = [
    {
      id: "overview",
      title: "Overview",
      disabled: false,
      component: <Overviewrapper />,
    },
    //  {
    //   id: "chat",
    //   title: "Chat",
    //   disabled: false,
    //   component: <ChatBot />,
    // },
    {
      id: "entities",
      title: "Entities",
      disabled: false,
      component: <Entitieswrapper />,
    },
    {
      id: "feed",
      title: "Feed",
      disabled: false,
      component: <Feedwrapper />,
    },
    {
      id: "connection",
      title: "Connections",
      disabled: false,
      component: <Connectionswrapper />,
    },
    {
      id: "map",
      title: "Map",
      disabled: false,
      component: <Mapwrapper />,
    },
    // {
    //   id: "chat",
    //   title: "Chat",
    //   disabled: false,
    //   component: <ChatWrapper />,
    // },
  ];

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
    dispatch(setActiveAnalysisTab(tab));
    setSearchParams((params) => {
      params.set("subtab", tab);
      return params;
    });
  };

  useEffect(() => {
    setActiveTab(activeAnalysisTab);
  }, [activeAnalysisTab]);

  // useEffect(() => {
  //   if (queryParam) {
  //     dispatch(setActiveAnalysisTab(queryParam));
  //     setSearchParams((params) => {
  //       if (queryId) {
  //         params.set("query", queryId);
  //       }
  //       return params;
  //     });
  //   }
  // }, [queryParam, queryId]);

  useEffect(() => {
    if (queryParam) {
      setActiveTab(queryParam);
      dispatch(setActiveAnalysisTab(queryParam));
    }

    // if (queryId !== undefined) {
    //   dispatch(getQueryById(queryId)).then((res) => {
    //     if (res?.payload?.status === 200) {
    //       setQueryData(res?.payload?.data);
    //     }
    //     // else if(!res?.payload){ // handling if someone enters a queryId which doesn't exist
    //     //   handleRouteClick(`${routes.home.path}?tab=${activeTab}&page=${queryParam}&query=${queryData?.id}`);
    //     // }
    //   });
    // }
  }, []);

  useEffect(() => {
    if (queryId) {
      dispatch(getQueryById(queryId)).then((res) => {
        if (res?.payload?.status === 200) {
          setQueryData(res?.payload?.data);
        } else {
          dispatch(getLatestQuery()).then((res) => {
            if (res.payload.status === 200 && res.payload.data._id !== undefined) {
              dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
                if (res?.payload?.status === 200) {
                  setQueryData(res?.payload?.data);
                }
              });
            }
            handleRouteClick(`${routes.home.path}?tab=home&subtab=overview&query=${res?.payload?.data?._id}`);
          });
        }
      });
    } else {
      dispatch(getLatestQuery()).then((res) => {
        if (res.payload.status === 200 && res.payload.data._id !== undefined) {
          dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
            if (res?.payload?.status === 200) {
              setQueryData(res?.payload?.data);
            }
          });
        }
        handleRouteClick(`${routes.home.path}?tab=home&subtab=overview&query=${res?.payload?.data?._id}`);
      });
    }
  }, [queryId]);

  return (
    <Box height="100%" overflow="hidden">
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              background: theme.palette.background.tertiaryDarkGradient,
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              padding: "8px 10px",
              borderRadius: "4px",
              border: theme.palette.dashboardContainer.buttonBorder,
              width: "fit-content",
              [theme.breakpoints.only("md")]: {
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            <Typography variant="bigCaption" color={theme.palette.text.tableHeader}>
              Query:&nbsp;
            </Typography>
            <Typography
              variant="bigCaption"
              color={theme.palette.text.default}
              fontWeight={600}
              title={queryData?.text ? queryData?.text : queryData?.keywords?.join(", ")}
            >
              {queryData?.text ? textTruncate(queryData?.text, 30) : textTruncate(queryData?.keywords?.join(", "), 30)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={9} md={6} sx={{ textAlign: "center" }}>
          <CustomTab tabs={tabs} handleActiveTab={handleActiveTab} sx={{ p: 1 }} activeTab={activeTab} />
        </Grid>
        <Grid item xs={3} md={3} sx={{ textAlign: "right" }}>
          {/* <CustomSecondaryButton
            startIcon={<UploadIcon />}
            sx={{
              [theme.breakpoints.down("lg")]: {
                display: "none",
              },
            }}
          >
            Export Report
          </CustomSecondaryButton>
          <CustomIconButton
            sx={{
              color: theme.palette.text.default,
              width: "36px",
              height: "36px",
              [theme.breakpoints.up("lg")]: {
                display: "none",
              },
            }}
          >
            <UploadIcon />
          </CustomIconButton> */}
        </Grid>
      </Grid>
      <Box sx={{ height: "100%", overflow: "scroll", mt: 2, pb: 15 }}>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
    </Box>
  );
}
