import TableRowsIcon from "@mui/icons-material/TableRows";
import WindowIcon from "@mui/icons-material/Window";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { LuArrowDownUp } from "react-icons/lu";
import { useLocation } from "react-router-dom";
import CheckboxFilterButton from "../../../../../components/CheckboxFilterButton";
import CustomIconButton from "../../../../../components/CustomIconButton";
import CustomTab from "../../../../../components/CustomTab";
import RadioFilterButton from "../../../../../components/RadioFilterButton";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import CustomChipInput from "../../../../home/components/CustomCasePipeline/CustomChipInput";

export interface TabRecord {
  id: string | number;
  title: string;
  disabled?: boolean;
  component: React.ReactNode;
}

export interface TabLayoutProps {
  tabs: TabRecord[];
  getActiveTab: (tab: string) => void;
  getView: (view: boolean) => void;
  handleDrawerOpen(): void;
  openDrawer: boolean;
  setStateVariable?: any;
  getAuthenticitySorted: () => void;
  getRelevanceSorted: () => void;
  setRedFlags: any;
}

export default function FilterSection({ tabs, getActiveTab, getView, handleDrawerOpen, openDrawer, setStateVariable, getAuthenticitySorted, getRelevanceSorted, setRedFlags }: TabLayoutProps) {
  const theme = useTheme();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();

  const [activeTab, setActiveTab] = useState(state?.activeTab || tabs[0].id);
  const [sortedValue, setsortedValue] = useState("relevance");
  const [subtype, setSubType] = useState("all posts");
  const [listView, setListView] = useState(false);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
    getActiveTab(tab);
  };

  const handleRiskFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setsortedValue(e.target.value);
  };

  const handleTypeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubType(e.target.value);
  };

  const handleView = () => {
    setListView((prev) => !prev);
  };

  const handleFilter = () => {
    handleDrawerOpen();
  };

  const handleRedFlags = (value: string[]) => {
    setRedFlags(value);
  };

  useEffect(() => {
    getView(listView);
  }, [listView]);

  const subType = [
    { id: "all-posts", text: "All Posts" },
    { id: "top-sources", text: "Top Sources" },
    { id: "most-mentioned", text: "Most Mentioned" },
    { id: "most-used-hashtags", text: "Most Used Hashtags" },
    { id: "most popular", text: "Most Popular" },
  ];

  const sources = [
    { id: "all", text: "All" },
    { id: "news", text: "News" },
    { id: "twitter", text: "Twitter" },
    { id: "facebook", text: "Facebook" },
    { id: "instagram", text: "Instagram" },
    { id: "youtube", text: "Youtube" },
    { id: "linkedin", text: "Linkedin" },
    { id: "weibo", text: "Weibo" },
    { id: "indiatoday", text: "Indiatoday" },
    { id: "cnn", text: "CNN" },
    { id: "bbc", text: "BBC" },
  ];

  const relevance = [
    { id: "relevance", text: "Relevance" },
    { id: "autenticity", text: "Authenticity" },
    { id: "latest", text: "Latest" },
    { id: "oldest", text: "Oldest" },
    { id: "risk score: high first", text: "Risk Score: High First" },
    { id: "risk score: low first", text: "Risk Score: Low First" },
  ];

  const handleSortChange = (selectedOptionId: string) => {
    if (selectedOptionId === "autenticity") {
      getAuthenticitySorted();
    } else if (selectedOptionId === "relevance") {
      getRelevanceSorted();
    }
  };

  return (
    <Box gap="12px" display={"flex"} mt={2} justifyContent="space-between" sx={{ [theme.breakpoints.down("xl")]: { flexDirection: "column" } }}>
      <Box
        display="flex"
        gap={2}
        width={"100%"}
        height="40px"
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: openDrawer ? "column" : "row",
          },
        }}
      >
        <CustomTab
          tabs={tabs}
          handleActiveTab={handleActiveTab}
          sx={{
            border: `1px solid ${theme.palette.primary.tertiary}`,
            textAlign: "center",
            p: "0 2px",
            alignItems: "center",
            borderRadius: "6px",
            paddingX: "5px",
          }}
          width="110px"
          activeTab={activeTab}
        />
      </Box>
      <Box ml="auto" display="flex" gap="20px" alignItems={"center"} justifyContent="flex-end" minWidth={"700px"}>
        <CheckboxFilterButton
          label="Source"
          onChange={() => { }}
          options={sources}
          showSearch={false}
          sx={{ maxWidth: "180px" }}
          setStateVariable={setStateVariable}
        />
        <RadioFilterButton
          onChange={(selectedOptionId) => handleSortChange(selectedOptionId)}
          options={relevance}
          startIcon={<LuArrowDownUp color={theme.palette.primary.main} size={16} />}
          defaultValue={relevance[0].id}
          sx={{ width: "140px" }}
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Red Flags
        </Typography>
        {/* <CustomKeywords
          onHandleChange={(keyword) => {
            handleKeywords(keyword);
          }}
        /> */}
        <CustomChipInput
          onHandleChange={(redflags) => {
            handleRedFlags(redflags);
          }}
        />
        <CustomIconButton sx={{ width: "36px", height: "36px", ml: "auto", color: theme.palette.text.default }} onClick={handleView}>
          {listView ? <TableRowsIcon /> : <WindowIcon />}
        </CustomIconButton>
      </Box>
    </Box>
  );
}
