import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

interface StatsBoxProps {
    title: string;
    value: string | number;
    backgroundColor?: string; // Make backgroundColor optional
    textColor: string;
}

const StatsBox: React.FC<StatsBoxProps> = ({ title, value, backgroundColor, textColor }) => {
    const theme = useTheme(); // Accessing the theme using the hook

    const bgColor = backgroundColor || theme.palette.text.tableHeader || "#f4f4f4"; // Fallback color if theme doesn't have tableHeader

    return (
        <Box
            sx={{
                width: "350px", // Adjust width as per your requirement
                padding: "20px", // Increased padding for better readability
                backgroundColor: bgColor, // Use fallback color if not found in the theme
                borderRadius: "8px",
                boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: textColor, // Text color
            }}
        >
            <Typography variant="body1" color={theme.palette.primary.light}>
                {title}:
            </Typography>
            <Typography variant="h4" color={textColor} fontWeight={600}>
                {value}
            </Typography>
        </Box>
    );
};

const StatsBoxes: React.FC = () => {
    return (
        <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap", justifyContent: "space-evenly" }}>
            <StatsBox
                title="Active Queries"
                value={5}
                backgroundColor="#245785" // Use theme's tableHeader color for background
                textColor="#fff" // White text color
            />
            <StatsBox
                title="Total Results"
                value={4484}
                backgroundColor="#245785" // Use theme's tableHeader color for background
                textColor="#fff" // White text color
            />
            <StatsBox
                title="Languages"
                value={3}
                backgroundColor="#245785" // Use theme's tableHeader color for background
                textColor="#fff" // White text color
            />
            <StatsBox
                title="Last Update"
                value="2m ago"
                backgroundColor="#245785" // Use theme's tableHeader color for background
                textColor="#fff" // White text color
            />
        </Box>
    );
};

export default StatsBoxes;
