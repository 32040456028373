import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios";

import { deleteAPI, getAPI, postAPI } from "../../axios/utils";

import { BASE_URL, LCP_CORE_SERVICE_BASE_URL } from "../../utils/constants";

// Query
export const getQueries = createAsyncThunk("analysis/getQueries", async (queryParams: Record<string, any>) => {
  let url = `${BASE_URL}/lcproto/new-query/get-data/`;
  if (queryParams?.isFavourite) {
    url = `${url}?is_favourite=${queryParams?.isFavourite}`;
  }
  return getAPI(url);
});

export const getQueryResult = createAsyncThunk("analysis/getQueryResult", async (queryParams: Record<string, any>) => {
  // let url = `${BASE_URL}/lcproto/query/get-query-result/`;

  let url = `${BASE_URL}/lcproto/new-query/get-query-result/`;

  if ("nextPage" in queryParams) {
    return getAPI(queryParams["nextPage"]);
  }
  if ("queryId" in queryParams) {
    url = `${url}?query_id=${queryParams["queryId"]}&limit=20`;
  }
  if ("sort_by" in queryParams) {
    url = `${url}&sort_by=${queryParams["sort_by"]}`;
  }
  if ("specific_entity" in queryParams) {
    url = `${url}&specific_entity=${queryParams["specific_entity"]}`;
  }
  if ("redFlags" in queryParams && Array.isArray(queryParams["redFlags"]) && queryParams["redFlags"].length) {
    const redFlagsParam = queryParams["redFlags"].join(","); // Join list with commas
    url = `${url}&blacklisted_words=${encodeURIComponent(redFlagsParam)}`;
  }
  if ("sources" in queryParams && Array.isArray(queryParams["sources"]) && queryParams["sources"].length) {
    const sorucesParam = queryParams["sources"].join(","); // Join list with commas
    url = `${url}&sources=${encodeURIComponent(sorucesParam)}`;
  }
  if ("pageSize" in queryParams) {
    url = `${url}&page_size=${queryParams["pageSize"]}`;
  }
  if ("subtab" in queryParams) {
    url = `${url}&page=${queryParams["page"]}`;
  }
  return getAPI(url);
});

export const saveQueryResult = createAsyncThunk("analysis/saveQueryResult", async (data: { qid: string; result: string }) => {
  return postAPI(`${BASE_URL}/lcproto/query/save-query-result/`, data);
});

export const saveQuery = createAsyncThunk("analysis/saveQuery", async (data: { text: string; role: string; pipeline_id: string }) => {
  return axiosInstance.post(`${BASE_URL}/lcproto/new-query/save-query/`, data);
});

export const getQueryDetail = createAsyncThunk("analysis/getQueryDetail", async (dataId: string) => {
  // return getAPI(`${BASE_URL}/lcproto/query/get-query-result-detail/?data_id=${dataId}`);
  return getAPI(`${BASE_URL}/lcproto/apis/publication/${dataId}/`);
});

export const getQueryAnalyticsData = createAsyncThunk("analysis/getQueryAnalyticsData", async (queryId: string) => {
  return getAPI(`${LCP_CORE_SERVICE_BASE_URL}/lcproto/new-query/get-query-analytics-data/?query_id=${queryId}`);
});

export const getQueryById = createAsyncThunk("analysis/getQueryById", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/new-query/get-data/?id=${queryId}`);
});

export const getLatestQuery = createAsyncThunk("analysis/getLatestQuery", async () => {
  return getAPI(`${BASE_URL}/lcproto/new-query/get-latest-query-detail/`);
});

export const getQueryResultImages = createAsyncThunk("analysis/getQueryResultImages", async (data: { image_url: string }) => {
  return postAPI(`${BASE_URL}/lcproto/query/get-query-result-images/`, data);
});

//Entities
export const getEntities = createAsyncThunk("analysis/getEntities", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/entities/get-query-entities/?query_id=${queryId}`);
});

export const getEntityDetailById = createAsyncThunk("analysis/getEntityDetailById", async (entityId: string) => {
  return getAPI(`${BASE_URL}/lcproto/entities/get-entity-detail/?entity_id=${entityId}`);
});

// Connections
export const getConnections = createAsyncThunk("analysis/getConnections", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/query/get-query-connections/?query_id=${queryId}`);
});

//new  connection graph
export const getNewConnections = createAsyncThunk("analysis/getNewConnections", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/query/hashtag-counts/?query_id=${queryId}`);
});

// Posts by hashtag query
export const getPostsByHashtagQuery = createAsyncThunk(
  "analysis/getPostsByHashtagQuery",
  async ({ hashtagId, queryId }: { hashtagId: string; queryId: string }) => {
    return getAPI(
      `${BASE_URL}/lcproto/query/posts_by_hashtag/?hashtag_id=${encodeURIComponent(hashtagId)}&query_id=${encodeURIComponent(queryId)}`
    );
  }
);

// Entity Connections
export const getEntityConnections = createAsyncThunk("analysis/getEntityConnections", async (entityId: string) => {
  return getAPI(`${BASE_URL}/lcproto/entities/get-entity-connections-new/?entity_id=${entityId}`);
});

// Entity Crawl Data
export const getEntityCrawlData = createAsyncThunk("analysis/getEntityCrawlData", async (entityId: string) => {
  return getAPI(`${BASE_URL}/lcproto/entities/get-entity-crawldata/?entity_id=${entityId}`);
});

// TieredConnections
export const getTieredConnections = createAsyncThunk("analysis/getTieredConnections", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/new-query/get-query-connections-tiered/?query_id=${queryId}`);
});

// Unique Entities
export const getUniqueEntities = createAsyncThunk("analysis/getUniqueEntities", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/new-query/get-entities-by-query_id/?id=${queryId}`);
});

// User Profile Detail
export const getUserProfileDetail = createAsyncThunk("analysis/getUserProfileDetail", async (userId: string) => {
  return getAPI(`${BASE_URL}/lcproto/users/get-user-detail/?user_id=${userId}`);
});

export const addGlobalRedFlags = createAsyncThunk("analysis/addGlobalRedFlags", async (data: Record<string, any>) => {
  return postAPI(`${BASE_URL}/lcproto/global-red-flag/save-red-flag/`, data);
});

export const getGlobalRedFlags = createAsyncThunk("analysis/getGlobalRedFlags", async () => {
  return getAPI(`${BASE_URL}/lcproto/global-red-flag/get-red-flags/`);
});

export const getGlobalRedFlagsWithId = createAsyncThunk("analysis/getGlobalRedFlagsWithId", async () => {
  return getAPI(`${BASE_URL}/lcproto/globalredflag/`);
});

export const deleteGlobalRedFlag = createAsyncThunk("analysis/deleteGlobalRedFlag", async (keywordId: string) => {
  return deleteAPI(`${BASE_URL}/lcproto/global-red-flag/${keywordId}/delete-red-flag/`);
});

// get Related Articles from a entity ID
export const getFeedsByEntityId = createAsyncThunk("analysis/getFeedsByEntityId", async (entityId: string) => {
  return getAPI(`${BASE_URL}/lcproto/entities/get-entity-feed/?entity_id=${entityId}`);
});

// get Entities data for query id
export const getEntitiesData = createAsyncThunk("analysis/getFeedsByEntityId", async (queryId: string) => {
  return getAPI(`${BASE_URL}/lcproto/query/entity-counts/?query_id=${queryId}`);
});

// https://server.advis.skylarklabs.ai/lcproto/query/entity-counts/?query_id=${queryId}