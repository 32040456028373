import { createSlice } from "@reduxjs/toolkit";
import { getChatHistory, sendChatMessage } from "./chatThunk";

export type ResponseType = {
  answer: string;
  metadata: Record<string, any>;
};

export type MessageType = {
  id: string;
  prompt: string;
  timestamp: string;
  response: ResponseType;
};

export type ConversationalChatInitialStateType = {
  isLoading: boolean;
  chatsLoading: boolean;
  messages: Array<MessageType>;
  lastPrompt: string;
  islastPromptSuccess: boolean;
  selectedMessage: MessageType | null;
};

const conversationalChatInitialState: ConversationalChatInitialStateType = {
  isLoading: false,
  chatsLoading: false,
  messages: [],
  lastPrompt: "",
  islastPromptSuccess: true,
  selectedMessage: null
};

const conversationalSlice = createSlice({
  name: "conversational",
  initialState: conversationalChatInitialState,
  reducers: {
    pushMessages(state, action) {
      // state.messages = [...state.messages, action.payload];
    },
    setSelectedMessage(state, action) {
      state.selectedMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Send message
    builder
      .addCase(sendChatMessage.pending, (state, action) => {
        state.isLoading = true;
        state.lastPrompt = action.meta?.arg?.prompt;
        state.islastPromptSuccess = true
      })
      .addCase(sendChatMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastPrompt = "";
        state.messages = [...state.messages, action.payload?.data];
        state.selectedMessage = action.payload?.data;
      })
      .addCase(sendChatMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.islastPromptSuccess = false;
      });

     builder
      .addCase(getChatHistory.pending, (state, action) => {
        state.chatsLoading = true;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        state.chatsLoading = false;
        state.messages = action.payload?.data?.chats || [];
        state.selectedMessage = action.payload?.data?.chats[action.payload?.data?.chats.length - 1];
      })
      .addCase(getChatHistory.rejected, (state, action) => {
        state.chatsLoading = false;
      });
  },
});

export const conversationalActions = conversationalSlice.actions;

export default conversationalSlice;
