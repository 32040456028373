import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, Divider, Grid, ListItemIcon, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { useAppSelector } from "../../../store/hooks";
import NewChatBot from "./NewChatBox";

const SubHeader = () => {
    const theme = useTheme();
    const handleRouteClick = useHandleRouteClick();
    const [anchorEl, setAnchorEl] = useState(null); // For handling dropdown
    const open = Boolean(anchorEl);

    const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box height="100%" overflow="hidden">
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <Button
                        onClick={handleClick}
                        sx={{
                            background: theme.palette.background.tertiaryDarkGradient,
                            boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
                            padding: "8px 10px",
                            borderRadius: "4px",
                            border: theme.palette.dashboardContainer.buttonBorder,
                            // width: "fit-content",
                            [theme.breakpoints.only("md")]: {
                                // maxWidth: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            },
                        }}
                    >
                        <Typography variant="bigCaption" color={theme.palette.text.default} fontWeight={600}>
                            {query?.text}
                        </Typography>
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "lorem-ipsum-btn",
                        }}
                        sx={{
                            "& .MuiPaper-root": {
                                background: theme.palette.background.tertiaryDarkGradient,
                                color: theme.palette.text.default,
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                                borderRadius: "8px",
                            },
                        }}
                    >
                        <MenuItem onClick={() => {
                            handleRouteClick(`${routes.history.path}`);
                        }}>
                            <ListItemIcon>
                                <HistoryIcon fontSize="small" />
                            </ListItemIcon>
                            History
                        </MenuItem>

                        <Divider />

                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            Clear Conversation
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>

            <Box sx={{ height: "100%", overflow: "scroll", mt: 2, pb: 15 }}>
                <NewChatBot />
            </Box>
        </Box>
    );
};

export default SubHeader;