import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Box, Drawer, IconButton, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import UiLoading from "../../../layouts/UiLoading";
import { routes } from "../../../routes";
import { useHandleRouteClick } from "../../../routes/hooks";
import { getLatestQuery, getQueryById } from "../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ChatBotChatSection from "../../analysis/Components/Chat/ChatBotChatSection";
import ModifiedPublicationCard from "../../analysis/Components/Feed/components/Publications/ModifiedPublicationCard";
import { GraphComponent } from "./GraphComponent";

const drawerWidth = 450;

export default function NewChatBot() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const handleRouteClick = useHandleRouteClick();
  const [searchParams] = useSearchParams();
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const { selectedMessage } = useAppSelector((state) => state.conversational);
  const [queryData, setQueryData] = useState<Record<string, any>>(query);
  const { isLoading } = useAppSelector((state) => state.analysis);
  const [queryId, setQueryId] = useState<string | null>(searchParams.get("query"));
  const [selectedTab, setSelectedTab] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const handleToggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev); // Toggle drawer state
  };

  useEffect(() => {
    setQueryId(searchParams.get("query"));
  }, [searchParams]);

  useEffect(() => {
    setIsDrawerOpen(true);
  }, [selectedMessage]);

  useEffect(() => {
    if (queryId) {
      dispatch(getQueryById(queryId)).then((res) => {
        if (res?.payload?.status === 200) {
          setQueryData(res?.payload?.data);
        } else {
          dispatch(getLatestQuery()).then((res) => {
            if (res.payload.status === 200 && res.payload.data._id !== undefined) {
              dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
                if (res?.payload?.status === 200) {
                  setQueryData(res?.payload?.data);
                }
              });
            }
            handleRouteClick(`${routes.aiAssist.path}?tab=aiAssist&query=${res?.payload?.data?._id}`);
          });
        }
      });
    } else {
      dispatch(getLatestQuery()).then((res) => {
        if (res.payload.status === 200 && res.payload.data._id !== undefined) {
          dispatch(getQueryById(res?.payload?.data?._id)).then((res) => {
            if (res?.payload?.status === 200) {
              setQueryData(res?.payload?.data);
            }
          });
        }
        handleRouteClick(`${routes.aiAssist.path}?tab=aiAssist&query=${res?.payload?.data?._id}`);
      });
    }
  }, [queryId]);

  const TabPanel = ({ children, value, index }: any) => {
    return (
      <div role="tabpanel" hidden={value !== index} style={{ padding: 16 }}>
        {value === index && <Typography>{children}</Typography>}
      </div>
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
    >
      {isLoading ? (
        <UiLoading height="100%" />
      ) : (
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          width={isDrawerOpen ? `calc(100% - ${drawerWidth}px )` : "100%"}
          justifyContent="space-between"
          sx={{ flexGrow: 1 }}
        >
          {/* Main Content */}
          <Box height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            {/* Centered Company Logo and Text */}
            <Box textAlign="center">
              <img
                src="/sky-lark-labs-no-bg.png"
                alt="Company Logo"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "contain",
                  marginBottom: "10px",
                }}
              />
              <Typography variant="h1" color={theme.palette.text.titleLabel} fontWeight={600}>
                Get started with different solutions
              </Typography>
            </Box>
          </Box>

          <Box width="100%">
            <ChatBotChatSection queryId={queryId || ""} isDrawerOpen={isDrawerOpen} drawerWidth={drawerWidth} />
          </Box>
          <IconButton
            onClick={handleToggleDrawer}
            sx={{
              position: "absolute",
              top: "12%",
              right: isDrawerOpen ? drawerWidth - 40 : 16,
              zIndex: 1300,
              background: theme.palette.background.tertiaryDarkGradient,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: "15%",
              boxShadow: theme.palette.dashboardContainer.outsetBoxShadow,
              width: 48,
              height: 48,
            }}
          >
            {!isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Box>
      )}

      {/* Right Drawer */}
      <Drawer
        variant="persistent"
        anchor="right"
        open={isDrawerOpen}
        sx={{
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth - 50,
            height: "calc(100vh - 140px)", // Adjust height here
            marginTop: "100px",
            marginRight: "50px",
            boxSizing: "border-box",
            overflowY: "auto", // Ensures scrolling if content exceeds height
          },
        }}
      >
        {/* Drawer Content */}
        <Tabs value={selectedTab} onChange={handleTabChange} orientation="horizontal" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tab label="Knowledge Graph" />
          <Tab label="Citation" />
          {/* <Tab label="Map" /> */}
        </Tabs>
        <Box p={2} height="100%">
          <TabPanel value={selectedTab} index={0}>
            {/* <ConnectionsWrapper /> */}
            <GraphComponent graph_data={selectedMessage?.response.metadata?.graph} />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            {selectedMessage &&
              selectedMessage.response.metadata?.citations?.map((data: any, index: number) => {
                return (
                  <Box mb={1} key={index}>
                    <ModifiedPublicationCard cardData={data?.data} view={"grid"} matchedContent={data?.content} />
                  </Box>
                );
              })}
          </TabPanel>
          {/* <TabPanel value={selectedTab} index={2}>
                        Map Content
                    </TabPanel> */}
        </Box>
      </Drawer>
    </Box>
  );
}
