import { Box, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { analysisQueryActions } from "../../../../store/analysis/analysisQuerySlice";
import { getQueryResult } from "../../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { LCP_CORE_SERVICE_BASE_URL } from "../../../../utils/constants";
import FilterSection from "./components/FilterSection";
import Images from "./components/Images";
import Publication from "./components/Publications";
import Videos from "./components/Videos";

interface FeedSectionProps {
  isRefresh: boolean;
  removePageRefresh(): void;
}

export default function FeedSection({ isRefresh, removePageRefresh }: FeedSectionProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [params] = useSearchParams();

  const { feedFilter } = useAppSelector((state: any) => state.analysisQuery);
  const { query } = useAppSelector((state) => state.analysisQuery);

  const [activeTab, setActiveTab] = useState(state?.activeTab || "publication");
  const [view, setView] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sortBy, setSortBy] = useState("relevance");

  const [publicationData, setPublicationData] = useState<Record<string, any>>({});


  const [selectedSources, setSelectedSources] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [redFlags, setRedFlags] = useState<string[]>([]);
  // const [videosFeed , setVidoesFeed] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [moreLoading, setMoreLoading] = useState(false);
  const queryId = params.get("query") || query?.id;
  const specific_entity = params.get("specific_entity")

  const getQueryData = (queryId: string) => {
    setLoading(true);
    let sources  = selectedSources.map((item:any) => item.id);
    dispatch(getQueryResult({ queryId, sort_by: sortBy, redFlags, sources, specific_entity }))
      .then((res) => {
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPublicationData(res?.payload?.data);
          removePageRefresh();
        } else {
          toast.error("Failed to get the data.");
        }
        // console.log("🚀 ~ .then ~ res?.payload?.data:", res?.payload?.data);
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
        setLoading(false);
      });
  };

  const getAuthenticitySorted = useCallback(() => {
    
    setSortBy("authenticity");
    // setPublicationData((prevData: any) => {
    //   let sortedData;
    //   sortedData = [...prevData.results].sort((a, b) => b.authenticity - a.authenticity);

    //   const results = { results: sortedData }
    //   return results;
    // });
  }, []);

  const getRelevanceSorted = useCallback(() => {
    setSortBy("relevance");
    // setPublicationData((prevData: any) => {
    //   let sortedData;
    //   sortedData = [...prevData.results].sort((a, b) => b.relevance - a.relevance);

    //   const results = { results: sortedData }
    //   return results;
    // });
  }, []);

  const handleLoadMoreQueryData = () => {
    dispatch(getQueryResult({ nextPage: publicationData?.next_page.replace("http://lcpproto:8000", LCP_CORE_SERVICE_BASE_URL) }))
      .then((res) => {
        console.log("res:", res)
        setLoading(false);
        if (res?.payload?.status === 200) {
          setPublicationData((prev) => {
            return { ...prev, ...res?.payload?.data, results: [...prev.results, ...res?.payload?.data?.results] };
          });
          removePageRefresh();
          // console.log("publicationData", res.payload.data);
        } else {
          toast.error("Failed to get the data.");
        }
      })
      .catch((e) => {
        toast.error("Failed to get the data.");
        setLoading(false);
      });
  };

  useEffect(() => {
    // setPublicationData([]);
    if (queryId) {
      getQueryData(queryId);
    }
    if(specific_entity){
      toast.info("Showing Posts Belongs to Specific Entity!!")
    }
  }, [queryId, sortBy, redFlags, selectedSources, specific_entity]);

  useEffect(() => {
    if (queryId && isRefresh) {
      getQueryData(queryId);
    }
  }, [isRefresh]);

  // useEffect(() => {
  //   if (publicationData?.results?.length > 0) {

  //     // const videosData = publicationData?.results?.filter((feed: any) => feed?.videos !== null);
  //     // const imagesData = publicationData?.results?.flatMap((item: any) => (item.images ? item.images : []));
  //     // setVidoesFeed(videosData);
  //   }
  // }, [publicationData?.results]);

  const tabs = [
    {
      id: "publication",
      title: "Publications",
      disabled: false,
      component: (
        <Publication
          view={view}
          feedData={publicationData}
          loading={loading}
          drawerOpen={drawerOpen}
          isRefresh={isRefresh}
          loadMore={handleLoadMoreQueryData}
          selectedSources={[]}
          blacklistedWords={redFlags}
        />
      ),
    },
    {
      id: "image",
      title: "Images",
      disabled: false,
      component: <Images drawerOpen={drawerOpen} isRefresh={isRefresh} feedData={publicationData?.results} loading={loading} />,
    },
    {
      id: "video",
      title: "Videos",
      disabled: false,
      component: <Videos drawerOpen={drawerOpen} isRefresh={isRefresh} removePageRefresh={removePageRefresh} />,
    },
    // {
    //   id: "audio",
    //   title: "Audios",
    //   disabled: false,
    //   component: <Audio drawerOpen={drawerOpen} isRefresh={isRefresh} removePageRefresh={removePageRefresh} />,
    // },
  ];

  const getActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const getView = (viewTab: boolean) => {
    setView(viewTab);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisQueryActions.addFeedFilter(data));
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // Check if scrolled to the bottom
    if (scrollTop + clientHeight + 50 >= scrollHeight) {
      if(publicationData?.next && !moreLoading){
        setMoreLoading(true);
        getMoreData(); // Trigger your function
      }
    }
  }, [publicationData, moreLoading]);

  const getMoreData = () => {
    console.log(publicationData?.next)
    if(publicationData?.next){
      setMoreLoading(true);
      dispatch(getQueryResult({ nextPage: publicationData?.next }))
        .then((res) => {
          setMoreLoading(false);
          if (res?.payload?.status === 200) {
            setPublicationData((prevData: any) => {
              let data = res?.payload?.data
              data['results'] = [...prevData.results, ...data.results]
              return data;
            });
          } else {
            toast.error("Failed to get the data.");
          }
          // console.log("🚀 ~ .then ~ res?.payload?.data:", res?.payload?.data);
        })
        .catch((e) => {
          toast.error("Failed to get the data.");
          setMoreLoading(false);
        });
    }
    
  };

  

  return (
    <Box
    ref={containerRef}
      onScroll={handleScroll}
      sx={{
        height: "calc(100vh - 200px)",
        overflowY: "scroll",
        borderTop: "1px solid",
        borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1`,
        background: theme.palette.dashboardContainer.containerBackground,
      }}
    >
      {/* <Grid container spacing={2}> */}
      {/* {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={feedFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )} */}
      {/* <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}> */}
      <FilterSection
        tabs={tabs}
        getActiveTab={getActiveTab}
        getView={getView}
        handleDrawerOpen={handleDrawerOpen}
        openDrawer={drawerOpen}
        setStateVariable={setSelectedSources}
        getAuthenticitySorted={getAuthenticitySorted}
        getRelevanceSorted={getRelevanceSorted}
        setRedFlags={setRedFlags}
      />
      <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
      {moreLoading && <Typography>Loading More ...</Typography>}
      {/* </Grid> */}
      {/* </Grid> */}
    </Box>
  );
}
