import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, ImageList, ImageListItem, useTheme } from "@mui/material";
import React, { FC, useRef } from "react";
import CustomIconButton from "./CustomIconButton";

interface CustomVideoCarouselListProps {
  imageClickHandler?: () => void;
  videos: { id: number; img: string }[];
  source: string;
}

const CustomVideoCarousel: FC<CustomVideoCarouselListProps> = ({ imageClickHandler, videos, source }) => {
  const theme = useTheme();
  const listRef = useRef<any>(null);
  const [opennedImageIndex, setOpennedImageIndex] = React.useState(-1);

  const toggleImageViewer = (index: number) => {
    setOpennedImageIndex(index);
  };


  const arrowUpHandler = () => {
    if (listRef?.current && listRef?.current?.scrollLeft > 0) {
      const newPosition = listRef?.current?.scrollLeft - 50;
      listRef.current.scrollLeft = newPosition;
    }
  };

  const arrowDownHandler = () => {
    const scrollWidth: any = listRef.current?.scrollWidth;
    if (listRef?.current && listRef?.current?.scrollLeft < scrollWidth) {
      const newPosition = listRef?.current.scrollLeft + 50;
      listRef.current.scrollLeft = newPosition;
    }
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    // const imgElement = event.target as HTMLImageElement;
    // if (!imgElement.src.includes(window.location.origin) && source === "instagram") {
    //   dispatch(getQueryResultImages({ image_url: imgElement.src }))
    //     .then((res: any) => {
    //       const statusCode = parseInt(res?.error?.message?.split(" ")[5], 10);
    //       if (statusCode === 404) {
    //         setFeedImage(null);
    //       }
    //       if (res?.payload !== undefined) {
    //         if (res?.payload?.status === 201 || res?.payload?.status === 200) {
    //           setFeedImage(`data:image/png;base64,${res.payload.data.image}`);
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("errr", err);
    //     });
    // }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={2}
      sx={{
        width: "100%",
        background: (theme) => theme.palette.background.default,
        borderRadius: "4px",
      }}
    >
      <CustomIconButton
        sx={{
          width: "40px",
          minWidth: "40px",
          height: "40px",
          background: theme.palette.primary.tertiaryGradient,
          borderRadius: "50%",
          border: "none",
        }}
      >
        <KeyboardArrowLeftIcon
          onClick={arrowUpHandler}
          fontSize="large"
          cursor="pointer"
          sx={{
            margin: "10px 8px 10px 8px",
            color: theme.palette.primary.main,
          }}
        />
      </CustomIconButton>
      <ImageList
        ref={listRef}
        sx={{
          paddingLeft: "5px",
          paddingRight: "5px",
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "scroll",
          height: "194px",
          width: "100%",
        }}
        gap={14}
      >
        {videos?.map((v, idx) => (
          <ImageListItem
            key={idx}
            sx={{
              borderRadius: "4px",
              gap: 2,
              "& .MuiImageList-root": {
                gap: 2,
              },
            }}
          >
            <video
              src={v.img}
            //   onClick={() => toggleImageViewer(idx)}
              controls
              autoPlay={false}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "4px",
                objectFit: "cover",
              }}
            />
          </ImageListItem>
        ))}
        {/* <ImageViewer
          index={opennedImageIndex}
          images={videos.map((v_) => ({ src: v_.img }))}
          open={opennedImageIndex >= 0}
          onClose={() => setOpennedImageIndex(-1)}
        /> */}
      </ImageList>
      <CustomIconButton
        sx={{
          width: "40px",
          minWidth: "40px",
          height: "40px",
          background: theme.palette.primary.tertiaryGradient,
          borderRadius: "50%",
          border: "none",
        }}
      >
        <KeyboardArrowRightIcon
          onClick={arrowDownHandler}
          fontSize="large"
          cursor="pointer"
          sx={{
            margin: "10px 8px 10px 8px",
            color: (theme) => theme.palette.primary.main,
          }}
        />
      </CustomIconButton>
    </Box>
  );
};

export default CustomVideoCarousel;