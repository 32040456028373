import TabLayout from "../../layouts/TabLayout";
import ChatConatiner from "./ChatConatiner";

const tabs = [
  {
    id: "aiAssist",
    title: "Home",
    disabled: false,
    component: <ChatConatiner />,
  },
];

const NewHome = () => {
  return <TabLayout tabs={tabs} />;
};

export default NewHome;
