import { Box, Card, CardMedia, Link, Typography, useTheme } from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../store/hooks";

export const publicationImage = (source?: string) => {
  switch (source) {
    case "twitter":
      return "/assets/twitter_logo.png";
    case "facebook":
      return "/assets/facebook_logo.png";
    case "instagram":
      return "/assets/instagram_logo.png";
    case "cnn":
      return "/assets/cnn_logo.png";
    case "bbc":
      return "/assets/bbc_logo.jpg";
    case "indiatoday":
      return "/assets/indiatoday_logo.ico";
    case "linkedin":
      return "/assets/linkedin_icon.ico";
    case "youtube":
      return "/assets/youtube_logo.png";
    default:
      return "/assets/feed_logo.png";
  }
};

interface CardProps {
  [x: string]: any;
  view?: string;
  mergedColumnIds: number[];
  index: number;
  drawerOpen?: boolean;
}
export default function ImageCard({ cardData, mergedColumnIds, index, onError, drawerOpen = false }: CardProps) {
  const theme = useTheme();
  const handleRouteClick = useHandleRouteClick();
  const [showImage, setShowImage] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);
  const queryId = searchParams.get("query") || query?.query_id;

  let imgHeight: string = mergedColumnIds.includes(index) ? "548px" : "224px";

  const handleNavigation = (id: string) => {
    const publicationURL = routes.publication.path.replace(":queryId", queryId);
    const newURL = publicationURL.replace(":id", id);
    handleRouteClick(newURL);
  };

  return (
    <Box
      key={`image-${index}`}
      gridColumn={{ xs: "span 12", md: drawerOpen ? "span 12" : "span 6", lg: drawerOpen ? "span 6" : "span 4" }}
      gridRow={mergedColumnIds.includes(index) ? "span 2" : ""}
      sx={{
        display: showImage ? "block" : "none",
      }}
    >
      <Card
        sx={{
          background: theme.palette.background.darkCardBackground,
          border: theme.palette.additionalColors.databasecardBorder,
          borderRadius: 2,
          flexFlow: "row wrap",
          display: showImage ? "flex" : "none",
          height: "100%",
        }}
        onClick={() => handleNavigation(cardData?.dataId)}
      >
        <CardMedia
          onError={() => setShowImage(false)}
          component="img"
          image={cardData?.image}
          alt="data"
          sx={{ height: { xs: "224px", md: imgHeight } }}
        />
        <Box sx={{ p: 1.5, width: "100%", alignSelf: "flex-end" }}>
          <Box display="flex" gap={1}>
            <img
              onError={() => setShowImage(false)}
              src={cardData?.source === "news"? cardData?.fav_icon || publicationImage(cardData?.source) : publicationImage(cardData?.source)}
              alt="media"
              width={"20px"}
              height={"20px"}
              style={{ borderRadius: "2px" }}
            />
            <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
              Source:
              <Typography variant="body4" color={theme.palette.text.default}>
                <Link
                  href={cardData?.source_link}
                  underline="none"
                  target="_blank"
                  sx={{ ...theme.typography.body4, color: theme.palette.text.default, ml: 0.5 }}
                >
                  {cardData?.source}
                </Link>
              </Typography>
            </Typography>
            {cardData?.created_at && (
              <Typography variant="body4" color={theme.palette.text.tableHeader} ml="auto">
                {cardData?.created_at}
              </Typography>
            )}
          </Box>
          <Box display="flex" sx={{ width: "100%" }}>
            <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
              Relevance: {cardData?.relevance}
            </Typography>
            <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
              Authenticity: {cardData?.authenticity}
            </Typography>
            <CustomStatus label={cardData?.status} status={cardData?.status} sx={{ width: "88px", height: "28px", marginLeft: "auto" }} />
          </Box>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
            Author:
            <Typography variant="body4" color={theme.palette.text.default} mt={1} ml="1">
              &nbsp;{cardData?.author}
            </Typography>
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
