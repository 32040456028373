import { Box, Grid, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomFilterDrawer from "../../../../components/CustomFilterDrawer";
import CustomTab from "../../../../components/CustomTab";
import { useHandleRouteClick } from "../../../../routes/hooks";
import { analysisQueryActions } from "../../../../store/analysis/analysisQuerySlice";
import { getConnections, getNewConnections, getTieredConnections } from "../../../../store/analysis/analysisThunk";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Analytics from "./Components/Analytics";
import GraphView from "./Components/GraphView";
import NewGraphView from "./Components/NewGraphView";
import TableView from "./Components/TableView";

export default function ConnectionSection() {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const handleRouteClick = useHandleRouteClick();
  const [queryParameters] = useSearchParams();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const { connectionFilter } = useAppSelector((state: any) => state.analysis);

  // Define tabs with disabled flags, ensuring only New Graph tab is enabled
  const tabs = [
    {
      id: "graph",
      title: "Graph View",
      disabled: true, // Disable this tab
      component: <GraphView />,
    },
    {
      id: "new_graph",
      title: "Graph View",
      disabled: false, // Enable this tab
      component: <NewGraphView />,
    },
    {
      id: "table",
      title: "Table View",
      disabled: true, // Disable this tab
      component: <TableView />,
    },
    {
      id: "analytics",
      title: "Analytics",
      disabled: true, // Disable this tab
      component: <Analytics drawerOpen={drawerOpen} />,
    },
  ];

  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(state?.activeTab || tabs[1].id); // Default to New Graph Tab

  const handleDrawerOpen = () => {
    setDrawerOpen((prev) => !prev);
  };

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleFilterApply = (data: any) => {
    dispatch(analysisQueryActions.addConnectionFilter(data));
  };

  const handleReset = (data: any) => {
    dispatch(analysisQueryActions.addConnectionFilter(data));
  };

  console.log("queryParameters :", queryParameters);

  React.useEffect(() => {
    let queryId = queryParameters.get("query");
    if (queryId) {
      dispatch(getConnections(queryId));
      dispatch(getNewConnections(queryId));
      dispatch(getTieredConnections(queryId));
    }
  }, [queryParameters]);

  // Filter tabs to only include non-disabled ones
  const activeTabs = tabs.filter((tab) => !tab.disabled);

  return (
    <Box sx={{ borderTop: "1px solid", borderImage: `radial-gradient(${theme.palette.primary.main}, transparent) 1` }}>
      <Box display="flex" gap={2} width={"100%"} mt={2}>
        <CustomTab
          tabs={activeTabs} // Pass only non-disabled tabs to the CustomTab component
          handleActiveTab={handleActiveTab}
          sx={{ border: `1px solid ${theme.palette.primary.tertiary}`, textAlign: "center", borderRadius: "6px", p: "0 2px", alignItems: "center" }}
          width="110px"
          activeTab={activeTab}
        />
      </Box>
      <Grid container spacing={2} pt={2}>
        {drawerOpen && (
          <Grid item xs={5} lg={3}>
            <CustomFilterDrawer
              filterData={connectionFilter}
              openDrawer={drawerOpen}
              closePlayCamerasDrawer={handleDrawerOpen}
              handleFilterApply={handleFilterApply}
              handleReset={handleReset}
            />
          </Grid>
        )}
        <Grid item xs={drawerOpen ? 7 : 12} lg={drawerOpen ? 9 : 12}>
          <Box>{tabs.find((tab) => tab.id === activeTab)?.component}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}
