import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { MultiSelectValueType } from "../../../../components/CheckboxFilterButton";
import CustomInputField from "../../../../components/CustomInputField";
import MultiSelectAutocomplete from "../../../../components/MultiSelectAutocomplete";
import PrimaryLoadingButton from "../../../../components/PrimaryLoadingButton";
import { QueryProps } from "./CustomCasePreview";
import CustomChipInput from "./CustomChipInput";
import Customdespinput from "./Customdespinput";

interface QueryInputProps {
  handleQuery(queryValue: QueryProps): void;
}

export default function QueryInputs({ handleQuery }: QueryInputProps) {
  const theme = useTheme();
  const [query, setQuery] = useState<QueryProps>({
    text: "",
    role: "",
    pipeline_id: "",
    languages: [],
    redflags: [],
    descriptors: [],
    sources: [],
    startDate: null,  // Add startDate to initial state
    EndDate: null,    // Add endDate to initial state
  });

  const [languages, setlanguages] = useState<MultiSelectValueType[]>([]);
  const [sources, setSources] = useState<MultiSelectValueType[]>([]);
  // const [keywords, setKeywords] = useState<string[]>([]);
  const handleQueryText = (value: string) => {
    setQuery({ ...query, text: value });
  };

  const handleRole = (value: string) => {
    setQuery({ ...query, role: value });
  };

  const handleLanguage = (value: MultiSelectValueType[]) => {
    const newValues = value.map((data) => data.text);
    //setlanguages((prev) => [...prev, ...newValues]);
    setQuery({ ...query, languages: newValues });
  };

  const handleRedFlags = (value: string[]) => {
    setQuery({ ...query, redflags: value });
  };

  const handleDescriptors = (value: string[]) => {
    setQuery({ ...query, descriptors: value });
  };

  const handleStartDateChange = (value: Dayjs | null) => {
    setQuery({ ...query, startDate: value?.toISOString() || "" });
  };

  const handleEndDateChange = (value: Dayjs | null) => {
    setQuery({ ...query, EndDate: value?.toISOString() || null }); // Use `null` if no value
  };

  const handleSource = (value: MultiSelectValueType[]) => {
    let newValues = value.map((data) => data.text);
    if (newValues.includes("News Crawler")) {
      newValues.push("Indiatoday Crawler");
      newValues.push("BBC Crawler");
      newValues.push("CNN Crawler");
      newValues = newValues.filter((data) => data !== "News Crawler");
    }
    else {
      newValues = newValues.filter((data) => data !== "Indiatoday Crawler" && data !== "BBC Crawler" && data !== "CNN Crawler");
    }
    if (newValues.includes("Internet Crawler")) {
      newValues.push("News Crawler");
      newValues = newValues.filter((data) => data !== "Internet Crawler");
    }
    setQuery({ ...query, sources: newValues });
  };

  const handleTimeSelection = (unit: string) => {
    const now = dayjs();
    let newStartDate = now;

    if (unit === "hour") newStartDate = now.subtract(1, "hour");
    else if (unit === "day") newStartDate = now.subtract(1, "day");
    else if (unit === "month") newStartDate = now.subtract(1, "month");

    setQuery({
      ...query,
      startDate: newStartDate.toISOString(),
      EndDate: now.toISOString(),
    });
  };

  const languageList: MultiSelectValueType[] = [
    {
      id: "en",
      text: "English",
    },
    {
      id: "hi",
      text: "Hindi",
    },
    {
      id: "zh",
      text: "Chinese",
    },
  ];

  const sourceList: MultiSelectValueType[] = [
    {
      id: "news",
      text: "News Crawler",
    },
    {
      id: "x",
      text: "Twitter Crawler",
    },
    {
      id: "fb",
      text: "Facebook Crawler",
    },
    {
      id: "ig",
      text: "Instagram Crawler",
    },
    {
      id: "yt",
      text: "Youtube Crawler",
    },
    {
      id: "li",
      text: "Linkedin Crawler",
    },
    {
      id: "weibo",
      text: "Weibo Crawler",
    },
    {
      id: "internet",
      text: "Internet Crawler",
    }
  ];

  return (
    <Box textAlign="center">
      <Typography variant="semiBold" sx={{ color: theme.palette.text.default }}>
        Query Inputs
      </Typography>
      <Box textAlign="left">
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Query
        </Typography>
        <CustomInputField
          value={query.text}
          required
          name="text"
          placeholder="Enter query"
          size="small"
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQueryText(e.target.value)}
          autoComplete="off"
          sx={{ width: "100%" }}
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, mt: 1.5, mb: 0.5 }}>
          Role
        </Typography>
        <CustomInputField
          value={query.role}
          required
          name="role"
          placeholder="Enter role"
          size="small"
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRole(e.target.value)}
          autoComplete="off"
          sx={{ width: "100%" }}
        />
        {/* <Typography
          variant="h4"
          sx={{
            color: theme.palette.text.tableHeader,
            my: 1.5,
          }}
        >
          Responsibilities
        </Typography>
        <MultiChipInputField placeholder="Enter comma separated list" separator={","} onValueChange={handleResponsiblity} /> */}
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Language
        </Typography>
        <MultiSelectAutocomplete
          isRequired={true}
          options={languageList}
          placeholder="Select Language"
          handleAutoChange={(value: MultiSelectValueType[]) => handleLanguage(value)}
          selectionData={languages}
          isReset={true}
          size="small"
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Source
        </Typography>
        <MultiSelectAutocomplete
          isRequired={true}
          options={sourceList}
          placeholder="Select Source"
          handleAutoChange={(value: MultiSelectValueType[]) => handleSource(value)}
          selectionData={sources}
          isReset={true}
          size="small"
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Red Flags
        </Typography>
        {/* <CustomKeywords
          onHandleChange={(keyword) => {
            handleKeywords(keyword);
          }}
        /> */}
        <CustomChipInput
          onHandleChange={(redflags) => {
            handleRedFlags(redflags);
          }}
        />
        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Descriptors
        </Typography>
        {/* <CustomKeywords
          onHandleChange={(keyword) => {
            handleKeywords(keyword);
          }}
        /> */}
        <Customdespinput
          onHandleChange={(descriptors) => {
            handleDescriptors(descriptors);
          }}
        />
        <Typography
          variant="h4"
          sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}
        >
          Start Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DateTimePicker
            value={query.startDate ? dayjs(query.startDate) : null}
            onChange={handleStartDateChange}
            slotProps={{
              textField: {
                placeholder: "Select start date and time",
                variant: "outlined",
                size: "small",
                fullWidth: true,
                autoComplete: "off",
                sx: {
                  width: "100%",
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.text.defaultInputField,
                  },
                  marginTop: "8px",
                  // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                  borderRadius: "6px",
                  "& :-webkit-autofill": {
                    transitionDelay: "9999s",
                  },
                 
                  "& .Mui-error .MuiInputBase-input": {
                    color: theme.palette.text.errorInputField,
                  },
                  "& .MuiInputBase-input:focus::placeholder": {
                    color: "transparent",
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    color:  theme.palette.text.titleLabel,
                  },
                  "&:active:hover .MuiInputBase-root": {
                    color:  theme.palette.text.activeInputField,
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#BDC8D3",
                    opacity: 0.12,
                  },
                  "& .MuiInputAdornment-root ": {
                    marginRight: "5px",
                  },
                  "& .MuiInputBase-adornedEnd": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    backgroundColor: "transparent",
                  },
                  "& .MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.text.defaultInputField,
                  },
                  "& .MuiInputBase-adornedEnd.Mui-focused .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "&:active:hover .MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiInputBase-adornedEnd.Mui-error .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiInputBase-adornedStart": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    backgroundColor: "transparent",
                  },
                  "& .MuiInputBase-adornedStart .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.text.defaultStartIcon,
                  },
                  "& .MuiInputBase-adornedStart.Mui-focused .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiButtonBase-root-MuiIconButton-root": {
                    backgroundColor: theme.palette.background.default,
                  },
                  "&:active .MuiButtonBase-root-MuiIconButton-root": {
                    backgroundColor: theme.palette.background.default,
                  },
                  "& .MuiInputBase-root": {
                    background: theme.palette.background.default,
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: "20px",
                    borderRadius: "6px",
                    boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    color:  theme.palette.text.titleLabel,
                  },
                  "& .MuiInputBase-input.Mui-error": {
                    color: theme.palette.error.main,
                  },
                  "& .MuiInputBase-input:invalid": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                  },
                },
              },
            }}
          />
        </LocalizationProvider>

        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          End Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={query.EndDate ? dayjs(query.EndDate as string) : null}
            onChange={handleEndDateChange}
            slotProps={{
              textField: {
                placeholder: "Select end date and time",
                variant: "outlined",
                size: "small",
                fullWidth: true,
                autoComplete: "off",
                sx: {
                  width: "100%",
                  "& .MuiInputBase-input::placeholder": {
                    color: theme.palette.text.defaultInputField,
                  },
                  marginTop: "8px",
                  // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                  borderRadius: "6px",
                  "& :-webkit-autofill": {
                    transitionDelay: "9999s",
                  },

                  "& .Mui-error .MuiInputBase-input": {
                    color: theme.palette.text.errorInputField,
                  },
                  "& .MuiInputBase-input:focus::placeholder": {
                    color: "transparent",
                  },
                  "& .MuiInputBase-root.Mui-focused": {
                    color: theme.palette.text.titleLabel,
                  },
                  "&:active:hover .MuiInputBase-root": {
                    color: theme.palette.text.activeInputField,
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#BDC8D3",
                    opacity: 0.12,
                  },
                  "& .MuiInputAdornment-root ": {
                    marginRight: "5px",
                  },
                  "& .MuiInputBase-adornedEnd": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    backgroundColor: "transparent",
                  },
                  "& .MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.text.defaultInputField,
                  },
                  "& .MuiInputBase-adornedEnd.Mui-focused .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "&:active:hover .MuiInputBase-adornedEnd .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiInputBase-adornedEnd.Mui-error .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiInputBase-adornedStart": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    backgroundColor: "transparent",
                  },
                  "& .MuiInputBase-adornedStart .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.text.defaultStartIcon,
                  },
                  "& .MuiInputBase-adornedStart.Mui-focused .MuiSvgIcon-root": {
                    width: "20px",
                    height: "20px",
                    color: theme.palette.primary.main,
                  },
                  "& .MuiButtonBase-root-MuiIconButton-root": {
                    backgroundColor: theme.palette.background.default,
                  },
                  "&:active .MuiButtonBase-root-MuiIconButton-root": {
                    backgroundColor: theme.palette.background.default,
                  },
                  "& .MuiInputBase-root": {
                    background: theme.palette.background.default,
                    fontWeight: theme.typography.fontWeightRegular,
                    fontSize: "20px",
                    borderRadius: "6px",
                    boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                    color: theme.palette.text.titleLabel,
                  },
                  "& .MuiInputBase-input.Mui-error": {
                    color: theme.palette.error.main,
                  },
                  "& .MuiInputBase-input:invalid": {
                    // boxShadow: theme.palette.dashboardContainer.customInputBoxShadow,
                  },
                },
              },
            }}
          />
        </LocalizationProvider>

        <Typography variant="h4" sx={{ color: theme.palette.text.tableHeader, my: 1.5 }}>
          Quick Select for Date
        </Typography>
        <Grid container spacing={2}>
          {["hour", "day", "month"].map((unit) => (
            <Grid item key={unit} xs={4}>
              <Button
                variant="outlined"
                onClick={() => handleTimeSelection(unit)}
                sx={{
                  width: "100%",
                  textTransform: "capitalize", // Ensure text matches other styles
                  borderRadius: "4px",
                }}
              >
                {unit === "hour" ? "1 Hour" : unit === "day" ? "1 Day" : "1 Month"}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
      <PrimaryLoadingButton
        sx={{
          ...theme.typography.hugeButton,
          width: "100%",
          my: 2,
        }}
        onClick={() => handleQuery(query)}
      >
        Query
      </PrimaryLoadingButton>
    </Box>
  );
}


