import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAPI, postAPI } from "../../axios/utils";
import { BASE_URL } from "../../utils/constants";

export const sendChatMessage = createAsyncThunk("conversational/sendChatMessage", async (data: Record<string, any>) => {
  return await postAPI(`${BASE_URL}/new_chat`, data);
});

export const getChatHistory = createAsyncThunk("conversational/getChatHistory", async (query_id: string) => {
  return await getAPI(`${BASE_URL}/get_chat_history?query_id=${query_id}`);
});