import { Grid } from "@mui/material";
import { memo, useEffect, useState } from "react";
import ModifiedPublicationCard from "./ModifiedPublicationCard";
interface ListProps {
  [x: string]: any;
}

interface ListViewProps {
  publicationData: ListProps[];
  drawerOpen: boolean;
  isRefresh: boolean;
  blacklistedWords?: string[];
  selectedSources?: any;
  
}
const parseDate = (dateString: string) => {
  const [time, meridian, date] = dateString.split(" ");
  const [hour, minute] = time.split(":");
  const [day, month, year] = date.split("-");
  const isAM = meridian === "AM";
  const jsMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].indexOf(month);
  return new Date(parseInt(year), jsMonth, parseInt(day), isAM ? parseInt(hour) : parseInt(hour) + 12, parseInt(minute));
};
const mostRecentCallBack = (a: Record<string, any>, b: Record<string, any>): number => {
  const timeA = a?.creation_time ? parseDate(a?.creation_time) : new Date(0);
  const timeB = b?.creation_time ? parseDate(b?.creation_time) : new Date(0);
  if (timeA < timeB) {
    return 1;
  }
  if (timeA > timeB) {
    return -1;
  }
  return 0;
};

const mostLikedSortedCallback = (a: Record<string, any>, b: Record<string, any>): number => {
  const firstItemReactions = a?.likes || a?.reactions || a?.reactioncount || 0;
  const secondItemReactions = b?.likes || b?.reactions || b?.reactioncount || 0;
  // console.log(firstItemReactions, secondItemReactions, item");
  if (parseInt(firstItemReactions) < parseInt(secondItemReactions)) {
    return -1;
  }
  if (parseInt(firstItemReactions) > parseInt(secondItemReactions)) {
    return 1;
  }
  return 0;
};

const mostInteractedCallback = (a: Record<string, any>, b: Record<string, any>): number => {
  const firstItemWeight =
    (a?.likes || a?.reactions || a?.reactioncount || 0) +
    (a?.comments || a?.commentcount || 0) +
    (a?.shared || a?.reposts || 0) +
    (a?.quotes || 0) +
    (a?.bookmarks || 0) +
    parseInt(a?.post_views || "0") +
    (a?.user?.followers || 0) +
    (a?.user?.totalposts || 0);
  const secondItemWeight =
    (b?.likes || b?.reactions || b?.reactioncount || 0) +
    (b?.comments || b?.commentcount || 0) +
    (b?.shared || b?.reposts || 0) +
    (b?.quotes || 0) +
    (b?.bookmarks || 0) +
    parseInt(b?.post_views || "0") +
    (b?.user?.followers || 0) +
    (b?.user?.totalposts || 0);
  if (parseInt(firstItemWeight) < parseInt(secondItemWeight)) {
    return -1;
  }
  if (parseInt(firstItemWeight) > parseInt(secondItemWeight)) {
    return 1;
  }
  return 0;
};

const GridView = ({ publicationData, drawerOpen, isRefresh, selectedSources, blacklistedWords }: ListViewProps) => {
  // const mostLiked = [...publicationData].sort(mostLikedSortedCallback);
  // const mostInteracted = [...publicationData].sort(mostInteractedCallback);
  // const mostRecent = [...publicationData].sort(mostRecentCallBack);
  // const topPosts = [...publicationData];
  // console.log("publicationData", mostLiked);
  // console.log("publicationData", mostInteracted);
  // console.log("publicationData", mostRecent);
  // console.log("publicationData", topPosts);

  // const [cardData, setCardData] = useState<any>(publicationData);

  // useEffect(() => {
  //   // setCardData([...publicationData].sort(mostRecentCallBack));
  //   setCardData([...publicationData]);
  // }, [publicationData]);

  // useEffect(() => {
  //   if (selectedSources.length > 0) {
  //     var sources = selectedSources.map((source: any) => source.id);
  //     var filteredData = publicationData.filter((feed: any) => sources.includes(feed.source));
  //     setCardData(filteredData);
  //   } else {
  //     setCardData([...publicationData].sort(mostRecentCallBack));
  //   }
  // }, [selectedSources]);

  return (
    <Grid container spacing={2}>
      {/* <Grid item container xs={3} gap={2}>
        <GridTitle title={"MOST LIKED"} count={mostLiked.length} />
        {mostLiked?.map((data: any, idx) => (
          <Grid key={`image-${idx}`} width="100%" item>
            <ModifiedPublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Grid> */}
      {/* <Grid item container xs={3} gap={2}>
        <GridTitle title={"MOST INTERACTED"} count={mostInteracted.length} />
        {mostInteracted?.map((data: any, idx) => (
          <Grid key={`image-${idx}`} item width="100%">
            <ModifiedPublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Grid> */}
      {/* <Grid item container gap={1} xs={12} sm={12} md={12} lg={12}> */}
      {/* <GridTitle title={"MOST RECENT"} count={mostRecent.length} /> */}
      {publicationData?.map((data: any, idx: Number) => (
        <Grid key={`image-${data._id}+${idx}`} item width="100%" xs={12} sm={5} md={4} lg={3}>
          <ModifiedPublicationCard cardData={data} view={"grid"} blacklistedWords={blacklistedWords}/>
        </Grid>
      ))}
      {/* </Grid> */}
      {/* <Grid item container xs={3} gap={2}>
        <GridTitle title={"TOP POSTS"} count={topPosts.length} />
        {topPosts?.map((data: any, idx) => (
          <Grid key={`image-${idx}`} item width="100%">
            <ModifiedPublicationCard cardData={data} view={"grid"} />
          </Grid>
        ))}
      </Grid> */}
    </Grid>
  );
};

export default GridView;
