import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomSecondaryButton from "../../../../../../components/CustomSecondaryButton";
import CustomStatus from "../../../../../../components/CustomStatus";
import { routes } from "../../../../../../routes";
import { useHandleRouteClick } from "../../../../../../routes/hooks";
import { useAppSelector } from "../../../../../../store/hooks";
import { formatDateTimeIntoDDMMMYYYY, textTruncate } from "../../../../../../utils/helper";
interface CardProps {
  [x: string]: any;
  view?: string;
  matchedContent?: string;
  blacklistedWords?: string[];
}

export const publicationImage = (source?: string) => {
  switch (source) {
    case "twitter":
      return "/assets/twitter_logo.png";
    case "facebook":
      return "/assets/facebook_logo.png";
    case "instagram":
      return "/assets/instagram_logo.png";
    case "cnn":
      return "/assets/cnn_logo.png";
    case "bbc":
      return "/assets/bbc_logo.jpg";
    case "indiatoday":
      return "/assets/indiatoday_logo.ico";
    case "linkedin":
      return "/assets/linkedin_icon.ico";
    case "youtube":
      return "/assets/youtube_logo.png";
    default:
      return "/assets/feed_logo.png";
  }
};

export default function ModifiedPublicationCard({ cardData, view, matchedContent, blacklistedWords=[]}: CardProps) {
  const theme = useTheme();
  const { showTranslatedFeed } = useAppSelector((state) => state.analysis);
  const { query } = useAppSelector((state: Record<string, any>) => state.analysisQuery);

  const handleRouteClick = useHandleRouteClick();
  // const date = cardData?.crawlData?.date?.split(" · ");
  const date = cardData?.creation_time?.split(" ");
  const [feedImage, setFeedImage] = useState<null | string>(null);
  const [imageBroken, setImageBroken] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { queryId } = useParams();
  const [activeThumb, setActiveThumb] = useState<"up" | "down" | null>(null);

  const queryID = queryId || searchParams.get("query") || query?.id;

  const [isBookmarked, setIsBookmarked] = useState(false);

  const handleBookmarkClick = () => {
    setIsBookmarked(!isBookmarked);
  };

  const handleThumbsUpClick = () => {
    setActiveThumb(activeThumb === "up" ? null : "up");
  };

  const handleThumbsDownClick = () => {
    setActiveThumb(activeThumb === "down" ? null : "down");
  };
  useEffect(() => {
    if (cardData?.images_local?.length > 0) {
      setFeedImage(cardData?.images_local[0]);
    }
  }, [cardData?.images_local]);

  const handleNavigation = (id: string) => {
    const publicationURL = routes.publication.path.replace(":queryId", queryID || "");
    const newURL = publicationURL.replace(":id", id);
    const blacklistedWordsParam = blacklistedWords?.join(",");
    console.log(blacklistedWordsParam)
    if (queryID && id) {
      // handleRouteClick(newURL);
      window.open(newURL + `?highlightText=${matchedContent||''}&highlightWords=${encodeURIComponent(blacklistedWordsParam||'')}`, "_blank");
    } else {
      if (!queryID && !id) toast.error("Query Id and  Publication Id not found");
      else if (!queryID) toast.error("Query Id not found");
      else toast.error("Publication Id not found");
    }
  };

  const handleMilitaryPresence = () => {
    handleRouteClick(
      routes.millitaryPresenceMap.path + `?lat=${cardData?.location[1]}&lng=${cardData?.location[2]}&label=${cardData?.location[0]}`,
      {}
    );
  };

  const formatHTML = (rawHTML: string) => {
    const formattedHTML = rawHTML?.replace(/\n/g, "<br/>");
    return formattedHTML;
  };

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    setImageBroken(true);
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        background: theme.palette.background.darkCardBackground,
        border: theme.palette.additionalColors.databasecardBorder,
        p: 2,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "400px",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      <Box width="100%">
        <Box display="flex" gap={2} alignItems={"center"}>
          <Box onClick={() => handleNavigation(cardData?._id)}>
            {cardData?.title && cardData?.title !== "NA" && cardData?.title ? (
              <Typography variant="bigCaption" color={theme.palette.text.titleLabel}>
                {textTruncate(cardData?.title, 60)}
              </Typography>
            ) : (
              <Box display="flex" gap={1}>
                <Avatar src={cardData?.user?.profile_image || cardData?.channelDetails?.thumbnail} />
                <Box mt={1}>
                  <Typography variant="bigCaption" color={theme.palette.text.titleLabel}>
                    {cardData?.user?.name ||
                      cardData?.channelDetails?.channel_title ||
                      (cardData?.authors && cardData.authors.length > 0 && cardData?.authors[0]?.name)}
                  </Typography>
                  <Typography variant="subtitle2" color={theme.palette.text.tableHeader}>
                    {cardData?.user?.handle}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box textAlign="right" display="flex" alignItems="flex-end">
            <Box ml="auto" display="flex" alignItems="center">
              {isBookmarked ? (
                <Bookmark
                  style={{
                    color: theme.palette.info.main,
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  onClick={handleBookmarkClick}
                />
              ) : (
                <BookmarkBorder
                  style={{
                    color: theme.palette.text.primary,
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  onClick={handleBookmarkClick}
                />
              )}
            </Box>
          </Box>
        </Box>

        <Box display="flex" gap={1} sx={{ marginY: "10px", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            {cardData?.creation_time && (
              <Typography variant="body4" color={theme.palette.text.tableHeader} flexGrow={1}>
                {`${date[2]} ${date[0]} ${date[1]}`}
              </Typography>
            )}
            {cardData?.publishedAt && (
              <Typography variant="body4" color={theme.palette.text.tableHeader}>
                {formatDateTimeIntoDDMMMYYYY(new Date(cardData.publishedAt))}
              </Typography>
            )}
          </Box>
          <Box>
            <IconButton sx={{ color: activeThumb === "up" ? theme.palette.info.main : theme.palette.text.primary }} onClick={handleThumbsUpClick}>
              {activeThumb === "up" ? <ThumbUpAltIcon /> : <ThumbUpOffAltIcon />}
            </IconButton>

            <IconButton sx={{ color: activeThumb === "down" ? theme.palette.info.main : theme.palette.text.primary }} onClick={handleThumbsDownClick}>
              {activeThumb === "down" ? <ThumbDownAltIcon /> : <ThumbDownOffAltIcon />}
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" gap={1} alignItems={"center"}>
          <img
            src={cardData?.source === "news" ? cardData?.fav_icon || publicationImage(cardData?.source) : publicationImage(cardData?.source)}
            alt="media"
            width={"20px"}
            height={"20px"}
            style={{ borderRadius: "2px" }}
          />
          <Typography variant="body4" color={theme.palette.text.tableHeader} display="flex">
            Source:
            <Typography variant="body4" color={theme.palette.text.default}>
              {cardData?.source == "news"
                ? cardData?.origin?.includes("//")
                  ? cardData?.origin?.split("//")[1]
                  : cardData?.source || cardData?.source
                : cardData?.source}
            </Typography>
          </Typography>
        </Box>

        <Box display="flex" mt={1} onClick={() => handleNavigation(cardData?._id)}>
          <Box>
            <Typography
              variant="body4"
              color={theme.palette.text.titleLabel}
              sx={{
                ...theme.typography,
                width: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 6,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{
                __html: formatHTML(
                  textTruncate(String(showTranslatedFeed && cardData?.translated_content ? cardData?.translated_content : cardData?.content), 144)
                ),
              }}
            />
          </Box>
          {cardData?.images_local?.length > 0 && !imageBroken && feedImage !== null && (
            <Box onClick={() => handleNavigation(cardData?._id)}>
              <img
                src={feedImage}
                alt="media"
                width={"120px"}
                height={"100px"}
                style={{ borderRadius: "8px", margin: "auto 0 auto auto" }}
                loading="lazy"
                onError={(e) => handleImageError(e)}
              />
              {cardData?.images_local?.length > 1 && (
                <Typography
                  variant="caption1"
                  sx={{
                    position: "relative",
                    zIndex: 1,
                    bottom: "32px",
                    right: "-90px",
                    background: theme.palette.text.titleLabel,
                    color: theme.palette.background.dark,
                    width: "30px",
                    height: "16px",
                    borderRadius: "4px",
                    textAlign: "center",
                    p: "3px",
                  }}
                >
                  +{cardData?.images?.length - 1}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        <Box display="flex" gap={1} mt={2} sx={{ width: "100%" }}>
          <Box display="flex" gap={1} ml="auto">
            {cardData?.military_movement === 1 && view !== "grid" && (
              <CustomSecondaryButton
                sx={{ ...theme.typography, height: "28px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMilitaryPresence();
                }}
              >
                Military Presence
              </CustomSecondaryButton>
            )}
            {cardData?.live_stream && (
              <CustomSecondaryButton
                sx={{ ...theme.typography, height: "28px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRouteClick(routes.videoFeed.path.replace(":feedId", cardData?.data_id));
                }}
              >
                Check Live Video
              </CustomSecondaryButton>
            )}
            <CustomStatus label={"Moderate"} status={"moderate"} sx={{ width: "88px", height: "28px", pointerEvents: "none" }} />
          </Box>
        </Box>
        <Box display="flex" gap={1} mt={2} sx={{ width: "100%" }}>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1}>
            Relevance: {cardData?.relevance}
          </Typography>
          <Typography variant="body4" color={theme.palette.text.tableHeader} mt={1} ml={2}>
            Authenticity: {cardData?.authenticity}
          </Typography>
        </Box>
      </Box>
      {cardData?.military_movement === 1 && view === "grid" && (
        <CustomSecondaryButton sx={{ ...theme.typography, height: "28px", ml: "auto", mt: 2 }} onClick={handleMilitaryPresence}>
          Military Presence
        </CustomSecondaryButton>
      )}
    </Box>
  );
}
