import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { DataChunkFilterOptions, TimePeriodFilterOptions } from "../utils/constants";
import RadioFilterButton from "./RadioFilterButton";

type LineChartData = Record<string, [string | number, number][]>;

interface LineChartProps {
  lineData: LineChartData | null;
}

const LineChart: React.FC<LineChartProps> = ({ lineData }) => {
  const [series, setSeries] = useState<{ name: string; data: { x: string | number; y: number }[] }[]>([]);
  const [timePeriod, setTimePeriod] = useState<string | null>("All");
  const [dataChunk, setDataChunk] = useState<string | null>("1 Week");

  const chartSeries = lineData
    ? Object.entries(lineData).map(([key, value]) => ({
      name: key,
      data: value.map(([x, y]) => ({ x, y })),
    }))
    : [];

  useEffect(() => {
    if (chartSeries.length > 0) {
      setSeries(chartSeries);
    }
  }, [lineData]);

  const chartData = {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ["#2EA3FF", "#E32E01", "#EDF142", "#2EA3FF", "#3B921D", "#05539A"],
      tooltip: {
        enabled: true,
        theme: "dark",
      },
      grid: {
        show: true,
        borderColor: "rgba(176, 182, 193, 0.15)",
        // position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      markers: {
        size: 2,
      },
      xaxis: {
        categories: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "League Spartan",
            fontWeight: 400,
            colors: "white",
          },
        },
      },
      yaxis: {
        categories: [0, 10, 20, 30, 40],
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "League Spartan",
            fontWeight: 400,
            colors: "white",
          },
        },
      },
      legend: {
        show: true,
        // position: "top",
        labels: {
          colors: "white",
          useSeriesColors: false,
        },
        offsetY: 6,
      },
    },
  };

  const handleTimePeriodChange = (value: string) => {
    setTimePeriod(value);
    console.log(value);
  };

  const handleDataChunkChange = (value: string) => {
    setDataChunk(value);
    console.log(value);
  };

  return (
    <Box
      sx={{
        background: (theme) => theme.palette.background.default,
        border: (theme) => `1px solid ${theme.palette.additionalColors.border}`,
        p: 2,
        borderRadius: "10px",
        height: "100%",
        marginTop: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px", marginLeft: "10px" }}>
        <Typography variant="h2">Activity Timeline</Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <RadioFilterButton
            label="Time Period"
            onChange={handleTimePeriodChange}
            options={TimePeriodFilterOptions}
          />
          <RadioFilterButton
            label="Data Chunk"
            onChange={handleDataChunkChange}
            options={DataChunkFilterOptions}
          />
        </Box>
      </Box>
      {series.length > 0 ? (
        <Chart options={chartData.options} series={series} type="line" width={"100%"} height={325} />
      ) : (
        <Box width="100%" height={"342px"} display="flex" justifyContent="center" alignItems="center">
          <ErrorOutlineIcon fontSize="large" />
        </Box>
      )}
    </Box>
  );
};

export default LineChart;
