import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { routes } from "../../../../../routes";
import { useHandleRouteClick } from "../../../../../routes/hooks";
import { getEntities } from "../../../../../store/analysis/analysisThunk";
import { EntitiesType } from "../../../../../store/analysis/entitiesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import EntitiesTable from "./EntitiesTable";

interface detailTypeProps {
  type: string;
}

const header = [
  { id: "name", label: "Entity Name", searchEnable: true, sortable: true, minWidth: 150, customIcon: true },
  { id: "type", label: "Entity Type", searchEnable: true, sortable: true, minWidth: 100 },
  // { id: "subtype", label: "Entity Sub Type", searchEnable: true, sortable: true, minWidth: 100 },
  // { id: "description", label: "Description", searchEnable: true, sortable: true, minWidth: 200 },
  // { id: "score", label: "Risk Score", searchEnable: true, sortable: true, type: "button", minWidth: 100 },
  { id: "mentions", label: "Mentions", searchEnable: false, sortable: true, minWidth: 100 },
];

export default function EntitiesDetails({ type }: detailTypeProps) {
  const { allEntities } = useAppSelector((state) => state.analysisEntities);
  const dispatch = useAppDispatch();
  const [queryParameters] = useSearchParams();
  const [filterData, setFilterData] = useState<EntitiesType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleRouteClick = useHandleRouteClick();
  const { entitiesPage } = useAppSelector((state: any) => state.magicEdit);
  const { isMagicEdit } = entitiesPage;

  useEffect(() => {
    let queryId = queryParameters.get("query");
    if (queryId) {
      setLoading(true);
      dispatch(getEntities(queryId)).then(()=>{}).catch(()=>{}).finally(()=>{setLoading(false)})
    }
  }, [queryParameters]);

  useEffect(() => {
    const allEntities_ = [...allEntities].sort((f_: Record<string, any>, s_: Record<string, any>) => {
      if (parseInt(f_.mentions) < parseInt(s_.mentions)) {
        return 1;
      }
      if (parseInt(f_.mentions) > parseInt(s_.mentions)) {
        return -1;
      }
      return 0;
    });
    const filterData = allEntities_.filter((entity: EntitiesType) => {
      if (type === "all") {
        return entity;
      } else {
        return entity?.type === type;
      }
    });
    setFilterData(filterData);
  }, [allEntities, type]);

  const getRowData = (data: any) => {
    var feedURL = routes.home.path;
    let queryId = queryParameters.get("query");
    feedURL += "?tab=home&subtab=feed"
    feedURL += "&query="+queryId
    feedURL += "&specific_entity="+data?._id['$oid']
    window.open(feedURL, "_blank");
  };

  return (
    <Grid container sx={{ position: "relative" }}>
      <Grid item xs={12} mt={3}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}></Box>
        {loading? <><CircularProgress /> <Typography>Loading...</Typography> </>:<EntitiesTable
          header={header}
          tableData={filterData}
          rows={10}
          pagination={true}
          search={true}
          editing={isMagicEdit}
          handleClick={getRowData}
          type={type}
        />}
      </Grid>
    </Grid>
  );
}
