import Graph from "react-graph-vis";
import "vis-network/styles/vis-network.css";
import { parseDataToGraph } from "../utills/graphParser";
// need to import the vis network css in order to show tooltip

export function GraphComponent({ graph_data = [] }) {
  if (graph_data.length === 0) {
    return <></>;
  }
  const parsedData = parseDataToGraph(graph_data);
  const graph = {
    nodes: parsedData["nodes"],
    edges: parsedData["edges"],
  };
  const options = {
    nodes: {
      color: "#ffffff",
      shape: "dot",
      size: 10,
    },
    edges: {
      color: "#ffffff",
      smooth: {
        type: "cubicBezier",
        forceDirection: "horizontal",
        roundness: 0.4,
      },
    },
    height: "400px",
    width: "100%",
  };

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
    },
  };
  return (
    <Graph
      graph={graph}
      options={options}
      events={events}
      getNetwork={(network) => {
        //  if you want access to vis.js network api you can set the state in a parent component using this property
      }}
    />
  );
}
