export function parseDataToGraph(data) {
  const nodeTypes = {
    person: "#ffcc00", // yellow
    organization: "#66c2a5", // green
    location: "#fc8d62", // orange
    event: "#8da0cb", // purple
    topic: "#e78ac3", // pink
    other: "#a6d854", // light green
  };

  let nodes = [];
  let edges = [];

  // Iterate over each item in the data
  data.forEach((item) => {
    const { source, target, relation } = item;

    // Add source node if it doesn't exist
    if (!nodes.find((node) => node.id === source)) {
      nodes.push({
        id: source,
        label: source,
        title: source,
        color: nodeTypes["other"], // Default color as type isn't specified
        font: { size: 12, color: nodeTypes["other"], face: "arial" },
      });
    }

    // Add target node if it doesn't exist
    if (!nodes.find((node) => node.id === target)) {
      nodes.push({
        id: target,
        label: target,
        title: target,
        color: nodeTypes["other"], // Default color as type isn't specified
        font: { size: 12, color: nodeTypes["other"], face: "arial" },
      });
    }

    // Add edge
    edges.push({
      from: source,
      to: target,
      title: relation,
    });
  });

  return { nodes, edges };
}

