import { Box } from "@mui/material";
import { useState } from "react";
import { useAppSelector } from "../../../../../store/hooks";
import NewNetworkGraph from "./NewNetworkGraph"; // Import the NewNetworkGraph component

const NewGraphView = () => {
    const { hashtags } = useAppSelector((state) => state.analysisConnections);
    const [selectedNode, setSelectedNode] = useState<string | null>(null);
    const [visiblePosts, setVisiblePosts] = useState<string[]>([]);

    const handleBack = () => {
        setSelectedNode(null);
    };

    const handleNodeClick = (nodeId: string, posts: string[]) => {
        if (visiblePosts.includes(nodeId)) {
            // If posts for this node are already visible, hide them
            setVisiblePosts(visiblePosts.filter((id) => id !== nodeId));
        } else {
            // Otherwise, show the posts for this node
            setVisiblePosts([...visiblePosts, nodeId]);
        }
        setSelectedNode(nodeId); // Set selected node to update view
    };

    return (
        <Box mt={2}>
            <Box>
                <Box mt={3}>
                    {/* Pass visiblePosts and handleNodeClick to NewNetworkGraph */}
                    <NewNetworkGraph
                        setSelectedNode={setSelectedNode}
                        visiblePosts={visiblePosts}
                        onNodeClick={handleNodeClick}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default NewGraphView;
